import React, { useState } from "react";
import { Button, Form, Input, Alert, Layout, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import bgImage from "../assets/bg.jpg"; // Adjust the path if needed

const { Content, Sider } = Layout;
const { Title } = Typography;
const path = process.env.REACT_APP_API_PATH;

const Register = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    try {
      const response = await axios.post(`${path}/register`, {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
      });
      setSuccess("User registered successfully!");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setError(error.response.data.error || "Registration failed");
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
      }}
    >
      <Sider theme="dark">
        {/* Side menu or additional components can go here */}
      </Sider>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white to make the form stand out
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: 400,
            padding: "24px",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
          }}
        >
          {error && (
            <Alert
              message={error}
              type="error"
              showIcon
              closable
              afterClose={() => setError("")}
            />
          )}
          {success && (
            <Alert
              message={success}
              type="success"
              showIcon
              closable
              afterClose={() => setSuccess("")}
            />
          )}
          <Form layout="vertical" onFinish={handleSubmit}>
            <Title level={3} style={{ color: "black", textAlign: "center" }}>
              REGISTER
            </Title>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
            >
              <Input onChange={(e) => setFirstName(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: "Please input your last name!" },
              ]}
            >
              <Input onChange={(e) => setLastName(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input type="email" onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                { required: true, message: "Please input your phone number!" },
              ]}
            >
              <Input onChange={(e) => setPhoneNumber(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password onChange={(e) => setPassword(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                { required: true, message: "Please confirm your password!" },
              ]}
            >
              <Input.Password
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Register
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="default" block onClick={handleLogin}>
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </Layout>
  );
};

export default Register;
