import {
  Button,
  Modal,
  Input,
  Select,
  message,
  Tooltip,
  Form,
  Card,
} from "antd";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const path = process.env.REACT_APP_API_PATH;
const { Option } = Select;

// Define your time zones here
const timeZones = [
  { label: "(UTC-12:00) International Date Line West", tzCode: "-12:00" },
  { label: "(UTC-11:00) Coordinated Universal Time-11", tzCode: "-11:00" },
  { label: "(UTC-10:00) Hawaii", tzCode: "-10:00" },
  { label: "(UTC-09:00) Alaska", tzCode: "-09:00" },
  { label: "(UTC-08:00) Pacific Time (US & Canada)", tzCode: "-08:00" },
  { label: "(UTC-07:00) Mountain Time (US & Canada)", tzCode: "-07:00" },
  { label: "(UTC-06:00) Central Time (US & Canada)", tzCode: "-06:00" },
  { label: "(UTC-05:00) Eastern Time (US & Canada)", tzCode: "-05:00" },
  { label: "(UTC-04:00) Atlantic Time (Canada)", tzCode: "-04:00" },
  { label: "(UTC-03:00) Buenos Aires, Georgetown", tzCode: "-03:00" },
  { label: "(UTC-02:00) Mid-Atlantic", tzCode: "-02:00" },
  { label: "(UTC-01:00) Azores, Cape Verde Islands", tzCode: "-01:00" },
  { label: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London", tzCode: "+00:00" },
  { label: "(UTC+01:00) Amsterdam, Berlin, Rome, Paris", tzCode: "+01:00" },
  { label: "(UTC+02:00) Athens, Bucharest, Cairo", tzCode: "+02:00" },
  { label: "(UTC+03:00) Moscow, St. Petersburg, Riyadh", tzCode: "+03:00" },
  { label: "(UTC+03:30) Tehran", tzCode: "+03:30" },
  { label: "(UTC+04:00) Abu Dhabi, Baku, Muscat", tzCode: "+04:00" },
  { label: "(UTC+04:30) Kabul", tzCode: "+04:30" },
  { label: "(UTC+05:00) Islamabad, Karachi, Tashkent", tzCode: "+05:00" },
  {
    label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    tzCode: "+05:30",
  },
  { label: "(UTC+05:45) Kathmandu", tzCode: "+05:45" },
  { label: "(UTC+06:00) Almaty, Dhaka", tzCode: "+06:00" },
  { label: "(UTC+06:30) Yangon (Rangoon)", tzCode: "+06:30" },
  { label: "(UTC+07:00) Bangkok, Hanoi, Jakarta", tzCode: "+07:00" },
  { label: "(UTC+08:00) Beijing, Hong Kong, Singapore", tzCode: "+08:00" },
  { label: "(UTC+09:00) Tokyo, Seoul, Osaka", tzCode: "+09:00" },
  { label: "(UTC+09:30) Adelaide, Darwin", tzCode: "+09:30" },
  { label: "(UTC+10:00) Brisbane, Sydney, Melbourne", tzCode: "+10:00" },
  { label: "(UTC+11:00) Solomon Islands, New Caledonia", tzCode: "+11:00" },
  { label: "(UTC+12:00) Auckland, Wellington, Fiji", tzCode: "+12:00" },
  { label: "(UTC+13:00) Nuku'alofa", tzCode: "+13:00" },
];

export default function Integrations({ userId }) {
  const { t } = useTranslation();

  // Modal state management for each integration
  const [openTwilioModal, setOpenTwilioModal] = useState(false);
  const [openResendModal, setOpenResendModal] = useState(false);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [openCrmModal, setOpenCrmModal] = useState(false);
  const [openSmsFromNumberModal, setOpenSmsFromNumberModal] = useState(false); // For SMS "From Number"
  const [openCompanyEmailModal, setOpenCompanyEmailModal] = useState(false); // For Company Email

  const [loading, setLoading] = useState(false);

  // Twilio Integration Form Submission
  async function onTwilioSubmit(values) {
    const { account_sid, auth_token } = values;

    try {
      setLoading(true);
      await axios.post(`${path}/integrate-twilio-int`, {
        account_sid,
        auth_token,
        user_id: userId,
      });
      setOpenTwilioModal(false);
      message.success(t("twilioIntegratedSuccess"));
    } catch (e) {
      console.error(e);
      message.error(t("twilioIntegratedFailure"));
    } finally {
      setLoading(false);
    }
  }

  // SMS "From Number" Form Submission
  async function onSmsFromNumberSubmit(values) {
    const { from_number } = values;

    try {
      setLoading(true);
      await axios.post(`${path}/set-sms-from-number`, {
        from_number,
        user_id: userId,
      });
      setOpenSmsFromNumberModal(false);
      message.success(t("smsFromNumberSetSuccess"));
    } catch (e) {
      console.error(e);
      message.error(t("smsFromNumberSetFailure"));
    } finally {
      setLoading(false);
    }
  }

  // Company Email Form Submission
  async function onCompanyEmailSubmit(values) {
    const { company_email } = values;

    try {
      setLoading(true);
      await axios.post(`${path}/set-company-email`, {
        company_email,
        user_id: userId,
      });
      setOpenCompanyEmailModal(false);
      message.success(t("companyEmailSetSuccess"));
    } catch (e) {
      console.error(e);
      message.error(t("companyEmailSetFailure"));
    } finally {
      setLoading(false);
    }
  }

  // Resend Integration Form Submission
  async function onResendSubmit(values) {
    const { resend_api_key, from_email } = values;

    try {
      setLoading(true);
      await axios.post(`${path}/integrate-resend`, {
        resend_api_key,
        user_id: userId,
      });
      setOpenResendModal(false);
      message.success(t("resendIntegratedSuccess"));
    } catch (e) {
      console.error(e);
      message.error(t("resendIntegratedFailure"));
    } finally {
      setLoading(false);
    }
  }

  // Calendly Integration Form Submission
  async function onCalendarSubmit(values) {
    const { check_slots_webhook, book_slot_webhook, time_zone } = values;

    try {
      setLoading(true);
      await axios.post(`${path}/integrate-calendar`, {
        check_slots_webhook: check_slots_webhook,
        book_slot_webhook: book_slot_webhook,
        user_id: userId,
      });
      setOpenCalendarModal(false);
      message.success(t("calendarIntegratedSuccess"));
    } catch (e) {
      console.error(e);
      message.error(t("calendarIntegratedFailure"));
    } finally {
      setLoading(false);
    }
  }

  // CRM Integration Form Submission
  async function onCrmSubmit(values) {
    const { crm_webhook_url } = values;

    try {
      setLoading(true);
      await axios.post(`${path}/integrate-crm`, {
        crm_webhook_url,
        user_id: userId,
      });
      setOpenCrmModal(false);
      message.success(t("crmIntegratedSuccess"));
    } catch (e) {
      console.error(e);
      message.error(t("crmIntegratedFailure"));
    } finally {
      setLoading(false);
    }
  }

  // Disconnect CRM
  async function onDisconnectCrm() {
    try {
      setLoading(true);
      await axios.post(`${path}/disconnect-crm`, { user_id: userId });
      message.success(t("crmDisconnectedSuccess"));
    } catch (e) {
      console.error(e);
      message.error(t("crmDisconnectedFailure"));
    } finally {
      setLoading(false);
    }
  }

  return (
    <div style={{ padding: "20px" }}>
      <h1>{t("integrations")}</h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          width: "100%",
        }}
      >
        {/* Twilio Integration */}
        <Card
          title={t("twilio")}
          style={{ width: "300px" }}
          actions={[
            <Button
              key="integrate"
              onClick={() => setOpenTwilioModal(true)}
              disabled={loading}
            >
              {t("integrateTwilio")}
            </Button>,
          ]}
        />

        {/* SMS From Number */}
        <Card
          title={t("smsFromNumber")}
          style={{ width: "300px" }}
          actions={[
            <Button
              key="set"
              onClick={() => setOpenSmsFromNumberModal(true)}
              disabled={loading}
            >
              {t("setSmsFromNumber")}
            </Button>,
          ]}
        />

        {/* Company Email */}
        <Card
          title={t("companyEmail")}
          style={{ width: "300px" }}
          actions={[
            <Button
              key="set"
              onClick={() => setOpenCompanyEmailModal(true)}
              disabled={loading}
            >
              {t("setCompanyEmail")}
            </Button>,
          ]}
        />

        {/* Resend Integration */}
        <Card
          title={t("resend")}
          style={{ width: "300px" }}
          actions={[
            <Button
              key="integrate"
              onClick={() => setOpenResendModal(true)}
              disabled={loading}
            >
              {t("integrateResend")}
            </Button>,
          ]}
        />

        {/* Calendly Integration */}
        <Card
          title={t("calendarIntegration")}
          style={{ width: "300px" }}
          actions={[
            <Button
              key="integrate"
              onClick={() => setOpenCalendarModal(true)}
              disabled={loading}
            >
              {t("integrateCalendar")}
            </Button>,
          ]}
        />

        {/* CRM Integration */}
        <Card
          title={t("crm")}
          style={{ width: "300px" }}
          actions={[
            <Tooltip key="tooltip" title={t("crmTooltip")}>
              <Button onClick={() => setOpenCrmModal(true)} disabled={loading}>
                {t("integrateCRM")}
              </Button>
            </Tooltip>,
            <Button
              key="disconnect"
              danger
              onClick={onDisconnectCrm}
              disabled={loading}
            >
              {t("disconnectCRM")}
            </Button>,
          ]}
        />
      </div>

      {/* Twilio Modal */}
      <Modal
        title={t("integrateTwilioTitle")}
        open={openTwilioModal}
        onCancel={() => setOpenTwilioModal(false)}
        footer={null}
      >
        <Form onFinish={onTwilioSubmit} layout="vertical">
          <Form.Item
            name="account_sid"
            label={t("twilioAccountSID")}
            rules={[{ required: true, message: t("requiredField") }]}
          >
            <Input placeholder={t("twilioAccountSID")} />
          </Form.Item>
          <Form.Item
            name="auth_token"
            label={t("twilioAuthToken")}
            rules={[{ required: true, message: t("requiredField") }]}
          >
            <Input placeholder={t("twilioAuthToken")} />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("integrateTwilio")}
          </Button>
        </Form>
      </Modal>

      {/* SMS From Number Modal */}
      <Modal
        title={t("setSmsFromNumberTitle")}
        open={openSmsFromNumberModal}
        onCancel={() => setOpenSmsFromNumberModal(false)}
        footer={null}
      >
        <Form onFinish={onSmsFromNumberSubmit} layout="vertical">
          <Form.Item
            name="from_number"
            label={t("fromNumber")}
            rules={[{ required: true, message: t("requiredField") }]}
          >
            <Input placeholder={t("fromNumber")} />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("setSmsFromNumber")}
          </Button>
        </Form>
      </Modal>

      {/* Company Email Modal */}
      <Modal
        title={t("setCompanyEmailTitle")}
        open={openCompanyEmailModal}
        onCancel={() => setOpenCompanyEmailModal(false)}
        footer={null}
      >
        <Form onFinish={onCompanyEmailSubmit} layout="vertical">
          <Form.Item
            name="company_email"
            label={t("companyEmail")}
            rules={[
              { required: true, message: t("requiredField") },
              { type: "email", message: t("invalidEmail") },
            ]}
          >
            <Input placeholder={t("companyEmail")} />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("setCompanyEmail")}
          </Button>
        </Form>
      </Modal>

      {/* Resend Modal */}
      <Modal
        title={t("integrateResendTitle")}
        open={openResendModal}
        onCancel={() => setOpenResendModal(false)}
        footer={null}
      >
        <Form onFinish={onResendSubmit} layout="vertical">
          <Form.Item
            name="resend_api_key"
            label={t("resendAPIKey")}
            rules={[{ required: true, message: t("requiredField") }]}
          >
            <Input placeholder={t("resendAPIKey")} />
          </Form.Item>
          {/* <Form.Item
            name="from_email"
            label={t("fromEmail")}
            rules={[
              { required: true, message: t("requiredField") },
              { type: "email", message: t("invalidEmail") },
            ]}
          >
            <Input placeholder={t("fromEmail")} />
          </Form.Item> */}
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("integrateResend")}
          </Button>
        </Form>
      </Modal>

      {/* Calendly Modal */}
      <Modal
        title={t("integrateCalendarTitle")}
        open={openCalendarModal}
        onCancel={() => setOpenCalendarModal(false)}
        footer={null}
      >
        <Form onFinish={onCalendarSubmit} layout="vertical">
          <Form.Item
            name="check_slots_webhook"
            label={t("checkSlotsWebhook")}
            rules={[{ required: true, message: t("requiredField") }]}
          >
            <Input placeholder={t("checkSlotsWebhook")} />
          </Form.Item>
          <Form.Item
            name="book_slot_webhook"
            label={t("bookSlotWebhook")}
            rules={[{ required: true, message: t("requiredField") }]}
          >
            <Input placeholder={t("bookSlotWebhook")} />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading}>
            {t("integrateCalendar")}
          </Button>
        </Form>
      </Modal>

      {/* CRM Modal */}
      <Modal
        title={t("integrateCRMTitle")}
        open={openCrmModal}
        onCancel={() => setOpenCrmModal(false)}
        footer={null}
      >
        <Form onFinish={onCrmSubmit} layout="vertical">
          <Form.Item
            name="crm_webhook_url"
            label={t("crmWebhookURL")}
            rules={[{ required: true, message: t("requiredField") }]}
          >
            <Input placeholder={t("crmWebhookURL")} />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("integrateCRM")}
          </Button>
        </Form>
      </Modal>
    </div>
  );
}
