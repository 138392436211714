import React, { useEffect, useState } from "react";
import { Card, Button, message, Typography, Space, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { CheckOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import "./PricingPage.css"; // Custom CSS for further styling

const { Title, Text } = Typography;

const stripePromise = loadStripe(
  "pk_test_51NwmsGEZHMjV6PdSG9H8mtxcz7uFdspyBJ2RWFlvjWe8NjpDjj43wpShWRpwHl8YeEiTTpw5Oqxjjv1PoEeoyET600YzOgBZgu"
);

const path = process.env.REACT_APP_API_PATH;

const PricingPage = () => {
  const [pricingDetails, setPricingDetails] = useState([]);
  const [addonPackages, setAddonPackages] = useState([]);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPricingDetails = async () => {
      try {
        const response = await axios.get(`${path}/admin/get-pricing-details`);
        const allPackages = response.data;

        const primaryPackages = allPackages.filter(
          (pkg) => pkg.package_type !== "addon"
        );
        const addons = allPackages.filter(
          (pkg) => pkg.package_type === "addon"
        );

        setPricingDetails(primaryPackages);
        setAddonPackages(addons);
      } catch (error) {
        console.error(t("errorFetchingPricingDetails"), error);
        message.error(t("failedToFetchPricingDetails"));
      }
    };

    fetchPricingDetails();
  }, []);

  const handleSubscribe = async (packageId) => {
    try {
      const stripe = await stripePromise;

      const response = await axios.post(`${path}/create-checkout-session`, {
        package_id: packageId,
      });

      const result = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });

      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.error(t("subscriptionError"), error);
      message.error(t("subscriptionFailed"));
    }
  };

  return (
    <div className="pricing-page-container">
      {/* Go Back Button */}
      <Button
        icon={<ArrowLeftOutlined />}
        type="link"
        onClick={() => navigate("/")}
        className="back-button"
      >
        {t("backToHomepage")}
      </Button>

      {/* Header Section */}
      <div className="pricing-header">
        <Title level={1}>{t("chooseYourRightPlan")}</Title>
        <Text type="secondary" className="pricing-subtext">
          {t("selectBestPlans")}
        </Text>
      </div>

      {/* Pricing Sections: Two Columns */}
      <Row gutter={32}>
        {/* Primary Packages Column */}
        <Col xs={24} sm={24} md={12}>
          <Title level={2} className="section-title">
            {t("primaryPackages")}
          </Title>
          <div className="pricing-cards-container">
            {Array.isArray(pricingDetails) && pricingDetails.length > 0 ? (
              pricingDetails.map((detail) => (
                <Card
                  key={detail._id}
                  className="pricing-card"
                  title={detail.package_name}
                  hoverable
                >
                  <Title level={2} className="pricing-amount">
                    {detail.price}€
                  </Title>
                  <ul className="features-list">
                    {Array.isArray(detail.features) ? (
                      detail.features.map((feature, index) => (
                        <li key={index} className="feature-item">
                          <CheckOutlined className="check-icon" />
                          {feature}
                        </li>
                      ))
                    ) : (
                      <li>{t("noFeaturesListed")}</li>
                    )}
                  </ul>
                  {user?.isSubscribed ? (
                    <Button
                      type="default"
                      disabled
                      className="subscribe-button"
                    >
                      {t("alreadySubscribed")}
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      className="subscribe-button"
                      onClick={() => handleSubscribe(detail._id)}
                    >
                      {t("subscribeNow")}
                    </Button>
                  )}
                </Card>
              ))
            ) : (
              <Text type="secondary">{t("noPricingDetailsAvailable")}</Text>
            )}
          </div>
        </Col>

        {/* Addon Packages Column */}
        <Col xs={24} sm={24} md={12}>
          <Title level={2} className="section-title">
            {t("addonPackages")}
          </Title>
          <div className="pricing-cards-container">
            {Array.isArray(addonPackages) && addonPackages.length > 0 ? (
              addonPackages.map((addon) => (
                <Card
                  key={addon._id}
                  className="pricing-card"
                  title={addon.package_name}
                  hoverable
                >
                  <Title level={2} className="pricing-amount">
                    {addon.price}€
                  </Title>
                  <ul className="features-list">
                    {Array.isArray(addon.features) ? (
                      addon.features.map((feature, index) => (
                        <li key={index} className="feature-item">
                          <CheckOutlined className="check-icon" />
                          {feature}
                        </li>
                      ))
                    ) : (
                      <li>{t("noFeaturesListed")}</li>
                    )}
                  </ul>
                  <Button
                    type="primary"
                    className="subscribe-button"
                    onClick={() => handleSubscribe(addon._id)}
                  >
                    {t("buyAddon")}
                  </Button>
                </Card>
              ))
            ) : (
              <Text type="secondary">{t("noAddonPackagesAvailable")}</Text>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PricingPage;
