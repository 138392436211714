import React, { useState } from "react";
import { Button, Form, Input, Alert, Layout, Typography } from "antd";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import bgImage from "../assets/bg.jpg";
import { LockOutlined } from "@ant-design/icons";

const { Content, Sider } = Layout;
const { Title } = Typography;

const Login = () => {
  const { signin } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    try {
      const user = await signin(email, password);
      if (user.isAdmin) {
        navigate("/admin");
      } else {
        navigate("/");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignup = () => {
    navigate("/register");
  };

  const handleForgotPassword = () => {
    navigate("/forgot_password");
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
      }}
    >
      <Sider theme="dark">
        {/* Side menu or additional components can go here */}
      </Sider>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white to make the form stand out
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: 400,
            padding: "24px",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
          }}
        >
          {error && (
            <Alert
              message={error}
              type="error"
              showIcon
              closable
              afterClose={() => setError("")}
            />
          )}
          <Form layout="vertical" onFinish={handleSubmit}>
            <Title level={3} style={{ color: "black", textAlign: "center" }}>
              LOGIN
            </Title>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input type="email" onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password onChange={(e) => setPassword(e.target.value)} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Login
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="default" block onClick={handleSignup}>
                Sign Up
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                block
                onClick={handleForgotPassword}
                style={{
                  backgroundColor: "#52c41a",
                  borderColor: "#52c41a",
                }}
                icon={<LockOutlined />}
              >
                Forgot Password?
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </Layout>
  );
};

export default Login;
