import React, { useState, useEffect } from "react";
import { Card, Button, Modal, message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";

const path = process.env.REACT_APP_API_PATH;

const UserProfile = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${path}/user-profile`, {
          headers: { Authorization: `Bearer ${user.token}` },
          params: { email: user.email },
        });
        setProfile(response.data);
      } catch (error) {
        console.error("Error fetching profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [user]);

  const generateInvoice = async () => {
    try {
      const response = await axios.post(
        `${path}/generate-invoice`,
        {
          email: user.email,
        },
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Failed to generate invoice:", error);
    }
  };

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const handleDeleteAccount = async () => {
    try {
      // Step 1: Delete the account via backend
      await axios.delete(`${path}/delete-account`, {
        headers: { Authorization: `Bearer ${user.token}` },
        data: { email: user.email },
      });

      // Display success message after successful deletion
      message.success("Account deleted successfully.");

      // Log the user out and navigate back to the homepage
      logout();
      navigate("/");
    } catch (error) {
      console.error("Failed to delete account:", error);
      message.error("Failed to delete account. Please try again.");
    } finally {
      setIsDeleteModalVisible(false); // Hide the delete confirmation modal
    }
  };

  const showCancelModal = () => {
    setIsCancelModalVisible(true);
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await axios.post(`${path}/cancel-subscription`, {
        email: user.email,
      });

      if (response.status === 200) {
        message.success("Subscription cancelled successfully.");
        setProfile((prevProfile) => ({
          ...prevProfile,
          is_subscribed: false,
        }));
      } else {
        message.error("Failed to cancel subscription. Please try again.");
      }
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      message.error("Cancellation failed. Please try again.");
    } finally {
      setIsCancelModalVisible(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div
      style={{ textAlign: "center", padding: "50px", background: "#f7f7f7" }}
    >
      <Card
        title="User Profile"
        style={{ maxWidth: "500px", margin: "0 auto", textAlign: "left" }}
      >
        {profile ? (
          <div>
            <p>
              <strong>{t("name")}:</strong> {profile.full_name}
            </p>
            <p>
              <strong>{t("email")}:</strong> {profile.username}
            </p>
            <p>
              <strong>{t("phoneNumber")}:</strong> {profile.phone_number}
            </p>
            <p>
              <strong>{t("subscriptionStatus")}:</strong>{" "}
              {profile.is_subscribed ? "Subscribed" : "Not Subscribed"}
            </p>
            <p>
              <strong>{t("callLimit")}:</strong> {profile.call_limit}
            </p>
            {profile.is_subscribed ? (
              <>
                <Button
                  type="default"
                  onClick={generateInvoice}
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  {t("generateAndDownloadInvoice")}
                </Button>
                <Button
                  type="danger"
                  onClick={showCancelModal}
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  {t("cancelSubscription")}
                </Button>
              </>
            ) : (
              <Button
                type="primary"
                onClick={() => navigate("/pricing")}
                style={{ width: "100%", marginBottom: "10px" }}
              >
                Upgrade to Pro
              </Button>
            )}
            <Button
              type="danger"
              onClick={showDeleteModal}
              style={{ width: "100%", marginBottom: "10px" }}
            >
              {t("deleteAccount")}
            </Button>
            <Button
              type="default"
              onClick={() => navigate("/")}
              style={{ width: "100%" }}
            >
              {t("backToHomepage")}
            </Button>
          </div>
        ) : (
          <p>Error loading profile</p>
        )}
      </Card>

      <Modal
        title="Confirm Account Deletion"
        visible={isDeleteModalVisible}
        onOk={handleDeleteAccount}
        onCancel={() => setIsDeleteModalVisible(false)}
        okText="Delete"
        okButtonProps={{ danger: true }}
      >
        <p>
          Are you sure you want to delete your account? This action cannot be
          undone.
        </p>
      </Modal>

      <Modal
        title="Confirm Subscription Cancellation"
        visible={isCancelModalVisible}
        onOk={handleCancelSubscription}
        onCancel={() => setIsCancelModalVisible(false)}
        okText="Cancel Subscription"
        okButtonProps={{ danger: true }}
      >
        <p>
          Are you sure you want to cancel your subscription? You will lose all
          benefits associated with it.
        </p>
      </Modal>
    </div>
  );
};

export default UserProfile;
