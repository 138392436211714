import React, { useEffect, useState } from "react";
import { Card, Button, message } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../context/AuthContext"; // Ensure correct path

const path = process.env.REACT_APP_API_PATH;

const SuccessPage = () => {
  const navigate = useNavigate();
  const { user, updateUser } = useAuth(); // Get updateUser from AuthContext
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get("session_id");

    if (sessionId) {
      verifyAndUpdateSubscription(sessionId);
    } else {
      console.error("Sitzungs-ID fehlt in der URL");
      message.error("Sitzungs-ID fehlt. Weiterleitung zur Preisseite.");
      navigate("/login");
    }
  }, [location, navigate]);

  const verifyAndUpdateSubscription = async (sessionId) => {
    try {
      const response = await axios.post(`${path}/api/subscription-success`, {
        session_id: sessionId,
      });

      setLoading(false); // Update loading state

      switch (response.status) {
        case 200:
          updateUser({ isSubscribed: true }); // Update user's subscription status in context

          message.success("Abonnement erfolgreich verifiziert!");
          navigate("/login"); // Navigate to user profile on success
          break;
        case 402:
          message.error("Zahlung nicht abgeschlossen.");
          navigate("/login"); // Navigate back to pricing page on payment error
          break;
        default:
          throw new Error(
            response.data.error ||
              "Unbekannter Fehler bei der Abonnementüberprüfung"
          );
      }
    } catch (error) {
      console.error("Fehler bei der Abonnementüberprüfung:", error);
      message.error(
        error.response?.data.error ||
          "Abonnementüberprüfung fehlgeschlagen. Bitte erneut versuchen."
      );
      navigate("/login"); // Redirect to pricing page to retry or handle differently
    }
  };

  return (
    <div
      style={{ textAlign: "center", padding: "50px", background: "#f7f7f7" }}
    >
      <Card
        style={{
          maxWidth: "350px",
          margin: "20px auto",
          padding: "20px",
          border: "1px solid #e8e8e8",
          boxShadow: "0 4px 8pp rgba(0, 0, 0, 0.1)",
        }}
      >
        {loading ? (
          <>
            <CheckCircleOutlined style={{ color: "green", fontSize: "2rem" }} />
            <h1 style={{ fontSize: "2.5rem", marginBottom: "10px" }}>
              Vielen Dank!
            </h1>
            <p style={{ fontSize: "1.2rem", color: "#777" }}>
              Ihr Abonnement wird verifiziert. Bitte warten Sie.
            </p>
          </>
        ) : (
          <>
            <CloseCircleOutlined style={{ color: "red", fontSize: "2rem" }} />
            <h1 style={{ fontSize: "2.5rem", marginBottom: "10px" }}>
              Überprüfung fehlgeschlagen
            </h1>
            <p style={{ fontSize: "1.2rem", color: "#777" }}>
              Ihr Abonnement konnte nicht verifiziert werden. Bitte überprüfen
              Sie Ihre Zahlungsdaten und versuchen Sie es erneut.
            </p>
          </>
        )}
        <Button
          type="default"
          onClick={() => navigate("/")}
          style={{ margin: "20px 0" }}
        >
          Zur Startseite
        </Button>
      </Card>
    </div>
  );
};

export default SuccessPage;
