import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AdminPage from "./pages/AdminPage";
import ProtectedRoute from "./components/ProtectedRoute";
import { ProvideAuth, useAuth } from "./context/AuthContext";
import UserProfile from "./pages/UserProfile";
import PricingPage from "./pages/PricingPage";
import SuccessPage from "./pages/SuccessPage";
import CancelPage from "./pages/CancelPage";
import InboundHomepage from "./pages/InboundHomepage";
import { useTranslation } from "react-i18next";
import "./App.css"; // This imports your global CSS file

const App = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Router>
      <ProvideAuth>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/reset_password" element={<ResetPassword />} />  

          {/* Public Routes */}
          <Route path="/pricing" element={<PricingPage />} />

          {/* Protected Routes */}
          <Route path="/" element={<ProtectedRoute />}>
            <Route index element={<Homepage />} />
          </Route>

          <Route path="/admin" element={<ProtectedRoute adminOnly={true} />}>
            <Route index element={<AdminPage />} />
          </Route>

          <Route path="/user-profile" element={<ProtectedRoute />}>
            <Route index element={<UserProfile />} />
          </Route>

          <Route path="/success">
            <Route index element={<SuccessPage />} />
          </Route>

          <Route path="/cancel">
            <Route index element={<CancelPage />} />
          </Route>

          <Route
            path="/inbound-homepage"
            element={<ProtectedRoute requiredSubscription={true} />}
          >
            <Route index element={<InboundHomepage />} />
          </Route>
        </Routes>
      </ProvideAuth>
    </Router>
  );
};

export default App;
