import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Select,
  message,
  Radio,
  DatePicker,
  TimePicker,
  Form,
  Button,
  Space,
  Tooltip,
  Row,
  Col,
} from "antd";

import {
  InfoCircleOutlined,
  ArrowRightOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import moment from "moment";
import UploadCSV from "./UploadCSV";

const timeZones = [
  { label: "(UTC-12:00) International Date Line West", tzCode: "-12:00" },
  { label: "(UTC-11:00) Coordinated Universal Time-11", tzCode: "-11:00" },
  { label: "(UTC-10:00) Hawaii", tzCode: "-10:00" },
  { label: "(UTC-09:00) Alaska", tzCode: "-09:00" },
  { label: "(UTC-08:00) Pacific Time (US & Canada)", tzCode: "-08:00" },
  { label: "(UTC-07:00) Mountain Time (US & Canada)", tzCode: "-07:00" },
  { label: "(UTC-06:00) Central Time (US & Canada)", tzCode: "-06:00" },
  { label: "(UTC-05:00) Eastern Time (US & Canada)", tzCode: "-05:00" },
  { label: "(UTC-04:00) Atlantic Time (Canada)", tzCode: "-04:00" },
  { label: "(UTC-03:00) Buenos Aires, Georgetown", tzCode: "-03:00" },
  { label: "(UTC-02:00) Mid-Atlantic", tzCode: "-02:00" },
  { label: "(UTC-01:00) Azores, Cape Verde Islands", tzCode: "-01:00" },
  { label: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London", tzCode: "+00:00" },
  { label: "(UTC+01:00) Amsterdam, Berlin, Rome, Paris", tzCode: "+01:00" },
  { label: "(UTC+02:00) Athens, Bucharest, Cairo", tzCode: "+02:00" },
  { label: "(UTC+03:00) Moscow, St. Petersburg, Riyadh", tzCode: "+03:00" },
  { label: "(UTC+03:30) Tehran", tzCode: "+03:30" },
  { label: "(UTC+04:00) Abu Dhabi, Baku, Muscat", tzCode: "+04:00" },
  { label: "(UTC+04:30) Kabul", tzCode: "+04:30" },
  { label: "(UTC+05:00) Islamabad, Karachi, Tashkent", tzCode: "+05:00" },
  {
    label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    tzCode: "+05:30",
  },
  { label: "(UTC+05:45) Kathmandu", tzCode: "+05:45" },
  { label: "(UTC+06:00) Almaty, Dhaka", tzCode: "+06:00" },
  { label: "(UTC+06:30) Yangon (Rangoon)", tzCode: "+06:30" },
  { label: "(UTC+07:00) Bangkok, Hanoi, Jakarta", tzCode: "+07:00" },
  { label: "(UTC+08:00) Beijing, Hong Kong, Singapore", tzCode: "+08:00" },
  { label: "(UTC+09:00) Tokyo, Seoul, Osaka", tzCode: "+09:00" },
  { label: "(UTC+09:30) Adelaide, Darwin", tzCode: "+09:30" },
  { label: "(UTC+10:00) Brisbane, Sydney, Melbourne", tzCode: "+10:00" },
  { label: "(UTC+11:00) Solomon Islands, New Caledonia", tzCode: "+11:00" },
  { label: "(UTC+12:00) Auckland, Wellington, Fiji", tzCode: "+12:00" },
  { label: "(UTC+13:00) Nuku'alofa", tzCode: "+13:00" },
];

const { Option } = Select;

// Pronunciation Guide Input Component
const PronunciationGuideInput = ({ value = [], onChange }) => {
  const { t } = useTranslation();
  const [guide, setGuide] = useState(value);

  const addEntry = () => {
    setGuide([
      ...guide,
      { word: "", pronunciation: "", case_sensitive: false, spaced: false },
    ]);
  };

  const updateEntry = (index, key, val) => {
    const updated = [...guide];
    updated[index][key] = val;
    setGuide(updated);
    onChange(updated);
  };

  const removeEntry = (index) => {
    const updated = [...guide];
    updated.splice(index, 1);
    setGuide(updated);
    onChange(updated);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {guide.map((entry, index) => (
        <Space key={index} style={{ width: "100%" }} direction="vertical">
          <Form.Item
            label={
              <span>
                Word{" "}
                <Tooltip
                  title={t("The word that needs pronunciation guidance.")}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            style={{ width: "100%", marginBottom: 0 }}
          >
            <Input
              value={entry.word}
              onChange={(e) => updateEntry(index, "word", e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                {t("pronunciation")}{" "}
                <Tooltip title={t("pronunciationWordTooltip")}>
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            style={{ width: "100%", marginBottom: 0 }}
          >
            <Input
              value={entry.pronunciation}
              onChange={(e) =>
                updateEntry(index, "pronunciation", e.target.value)
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                {t("caseSensitive")}{" "}
                <Tooltip title={t("caseSensitiveTooltip")}>
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            style={{ width: "100%", marginBottom: 0 }}
          >
            <Select
              value={entry.case_sensitive}
              onChange={(value) => updateEntry(index, "case_sensitive", value)}
            >
              <Option value={true}>{t("True")}</Option>
              <Option value={false}>{t("False")}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <span>
                {t("spaced")}{" "}
                <Tooltip title={t("spacedTooltip")}>
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            style={{ width: "100%", marginBottom: 0 }}
          >
            <Select
              value={entry.spaced}
              onChange={(value) => updateEntry(index, "spaced", value)}
            >
              <Option value={true}>{t("True")}</Option>
              <Option value={false}>{t("False")}</Option>
            </Select>
          </Form.Item>
          <Button type="danger" onClick={() => removeEntry(index)}>
            {t("Remove")}
          </Button>
        </Space>
      ))}
      <Button type="primary" onClick={addEntry} style={{ marginTop: 10 }}>
        {t("addPronunciation")}
      </Button>
    </Space>
  );
};

// Transfer List Input Component
const TransferListInput = ({ value = {}, onChange }) => {
  const [list, setList] = useState(value);
  const { t } = useTranslation();

  const updateEntry = (key, val) => {
    const updated = { ...list, [key]: val };
    setList(updated);
    onChange(updated);
  };

  const addEntry = () => {
    const key = `key${Object.keys(list).length + 1}`;
    updateEntry(key, "");
  };

  const removeEntry = (key) => {
    const updated = { ...list };
    delete updated[key];
    setList(updated);
    onChange(updated);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Form layout="vertical">
        {Object.keys(list).map((key) => (
          <Row key={key} gutter={16} style={{ marginBottom: 10 }}>
            <Col span={10}>
              <Form.Item label="Department" style={{ marginBottom: 0 }}>
                <Input
                  value={key}
                  onChange={(e) => {
                    const newKey = e.target.value;
                    const updated = { ...list, [newKey]: list[key] };
                    delete updated[key];
                    setList(updated);
                    onChange(updated);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label={t("phoneNumber")} style={{ marginBottom: 0 }}>
                <Input
                  value={list[key]}
                  onChange={(e) => updateEntry(key, e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={4} style={{ display: "flex", alignItems: "flex-end" }}>
              <Button
                type="danger"
                onClick={() => removeEntry(key)}
                style={{ width: "100%" }}
              >
                {t("remove")}
              </Button>
            </Col>
          </Row>
        ))}
      </Form>
      <Button type="primary" onClick={addEntry} style={{ marginTop: 10 }}>
        {t("addTransfer")}
      </Button>
    </Space>
  );
};

// Retry Configuration Input Component
// const RetryConfigurationInput = ({ value = {}, onChange }) => {
//   const [retryConfig, setRetryConfig] = useState(value);

//   const updateEntry = (key, val) => {
//     const updated = { ...retryConfig, [key]: val };
//     setRetryConfig(updated);
//     onChange(updated);
//   };
const RetryConfigurationInput = ({ value = {}, onChange }) => {
  const [retryConfig, setRetryConfig] = useState(value);
  const { t } = useTranslation();

  // Update the retryConfig state and convert minutes to seconds
  const updateEntry = (key, val) => {
    const minutes = parseFloat(val);
    const seconds = isNaN(minutes) ? 0 : minutes * 60; // Convert minutes to seconds
    const updated = { ...retryConfig, [key]: seconds };
    setRetryConfig(updated);
    onChange(updated);
  };

  // // Handle form submission if needed
  // const handleSubmit = () => {
  //   onChange(retryConfig);
  // };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Form.Item
        label={
          <span>
            {t("waitTime")}{" "}
            <Tooltip title={t("waitTimeTooltip")}>
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        }
        style={{ marginBottom: 0 }}
      >
        <Input
          placeholder={t("Enter wait time in minutes")}
          value={retryConfig.wait / 60 || ""} // Convert seconds to minutes for display
          onChange={(e) => updateEntry("wait", e.target.value)}
          style={{ width: "100%" }}
          type="number"
        />
      </Form.Item>
      <Form.Item
        label={
          <span>
            {t("voicemailAction")}{" "}
            <Tooltip title={t("voicemailActionTooltip")}>
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        }
        style={{ marginBottom: 0 }}
      >
        <Select
          placeholder={t("selectAction")}
          value={retryConfig.voicemail_action}
          onChange={(value) => updateEntry("voicemail_action", value)}
          style={{ width: "100%" }}
        >
          <Option value="hangup">{t("hangup")}</Option>
          <Option value="leave_message">{t("leaveMessage")}</Option>
          <Option value="ignore">{t("ignore")}</Option>
        </Select>
      </Form.Item>
      {retryConfig.voicemail_action === "leave_message" && (
        <Form.Item
          label={
            <span>
              Voicemail Message{" "}
              <Tooltip title={t("voicemailMessageTooltip")}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          style={{ marginBottom: 0 }}
        >
          <Input.TextArea
            placeholder={t("voicemailMessage")}
            value={retryConfig.voicemail_message}
            onChange={(e) => updateEntry("voicemail_message", e.target.value)}
            style={{ width: "100%" }}
          />
        </Form.Item>
      )}
    </Space>
  );
};

const StartCampaign = ({ modalVisible, setModalVisible }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [campaignId, setCampaignId] = useState("");
  const [selectedScript, setSelectedScript] = useState(null);
  const [firstSentence, setFirstSentence] = useState("");
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [voiceCategory, setVoiceCategory] = useState("normal"); // Default to 'normal'
  const [normalVoices, setNormalVoices] = useState([]);
  const [premiumVoices, setPremiumVoices] = useState([]);
  const [outboundNumber, setOutboundNumber] = useState(null);
  const [scheduleType, setScheduleType] = useState("immediate");
  const [loading, setLoading] = useState(false);
  const [scripts, setScripts] = useState([]);
  const [outboundNumbers, setOutboundNumbers] = useState([]);
  const [pronunciationGuide, setPronunciationGuide] = useState([]);
  const [transferList, setTransferList] = useState({});
  const [startTime, setStartTime] = useState(null);
  const [timeZone, setTimeZone] = useState("+00:00");
  const [retry, setRetry] = useState({});
  const [sms, setSms] = useState({ enabled: false, message: "" });
  const [email, setEmail] = useState({ enabled: false, message: "" });
  const [calendar, setCalendar] = useState({ enabled: false });
  const [csvData, setCsvData] = useState([]); // Define csvData state
  const [csvFile, setFile] = useState(null); // Define file state
  const [integrations, setIntegrations] = useState({});
  const [notesEmailEnabled, setNotesEmailEnabled] = useState(false); // State for enabling notes email
  const [companyEmail, setCompanyEmail] = useState(""); // State for company email

  useEffect(() => {
    if (user?.userId) {
      fetchScripts();
      fetchOutboundNumbers();
      fetchIntegrations(); // Fetch user integrations
    } else {
      message.error("User ID is required");
    }
  }, [user?.userId]);

  const fetchIntegrations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/user/integrations`,
        {
          params: { user_id: user.userId },
        }
      );
      setIntegrations(response.data);
    } catch (error) {
      console.error("Failed to fetch integrations:", error);
      message.error("Failed to fetch user integrations.");
    }
  };

  const fetchScripts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/user_scripts`,
        {
          params: { user_id: user.userId },
        }
      );
      setScripts(response.data);
      message.success("Scripts fetched successfully!");
    } catch (error) {
      console.error("Failed to fetch scripts:", error);
      message.error("Failed to fetch scripts.");
    }
  };

  const fetchNormalVoices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/voices`
      );
      const voices = response.data.voices || [];

      // Filter German voices if needed
      const germanVoices = voices.filter((voice) => {
        const description = voice.description || "";
        const tags = voice.tags || [];

        const isGermanTag = tags.some((tag) =>
          tag.toLowerCase().includes("german")
        );
        const isGermanDescription = description
          .toLowerCase()
          .includes("german");

        const commonGermanWords = [
          "und",
          "der",
          "die",
          "das",
          "ist",
          "nicht",
          "ein",
          "eine",
          "mit",
          "auf",
          "für",
          "von",
          "zu",
          "haben",
        ];
        const isDescriptionInGerman = commonGermanWords.some((word) =>
          description.toLowerCase().includes(word)
        );

        return isGermanTag || isGermanDescription || isDescriptionInGerman;
      });

      setNormalVoices(germanVoices);
      message.success("German voices fetched successfully!");
    } catch (error) {
      message.error("Failed to fetch normal voices: " + error.message);
    }
  };

  const fetchPremiumVoices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/admin/voices`
      );
      // Ensure response is properly formatted
      setPremiumVoices(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      message.error("Failed to fetch premium voices: " + error.message);
    }
  };

  useEffect(() => {
    // Clear both voice arrays before fetching the new category's voices
    setNormalVoices([]);
    setPremiumVoices([]);

    if (voiceCategory === "normal") {
      fetchNormalVoices(); // Fetch normal voices
    } else if (voiceCategory === "premium") {
      fetchPremiumVoices(); // Fetch premium voices
    }
  }, [voiceCategory]);

  const fetchOutboundNumbers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/outbound-numbers`,
        {
          params: { user_id: user.userId }, // Include userId in the request
        }
      );
      setOutboundNumbers(
        response.data.outbound_numbers.filter((number) => number.phone_number)
      );
      message.success("Outbound numbers fetched successfully!");
    } catch (error) {
      console.error("Failed to fetch outbound numbers:", error);
      message.error("Failed to fetch outbound numbers.");
    }
  };

  const handleScriptChange = (value) => {
    const selected = scripts.find((script) => script._id === value);
    setSelectedScript(selected);
    setFirstSentence(selected.greeting);
  };

  const startCampaign = async () => {
    if (!csvFile || !(csvFile instanceof File)) {
      message.error("Please upload a valid CSV file first.");
      return;
    }

    if (!csvData || csvData.length === 0) {
      message.error("The CSV file appears to be empty or invalid.");
      return;
    }

    if (notesEmailEnabled && !companyEmail) {
      message.error("Please provide a company email for notes.");
      return;
    }

    setLoading(true);
    setModalVisible(false);

    try {
      const formData = new FormData();
      formData.append("campaign_id", campaignId);
      formData.append("call_script", selectedScript.structured_content);
      formData.append("voice_id", selectedVoice);
      formData.append("first_sentence", firstSentence);
      formData.append("email", user.email);
      formData.append("outbound_number", outboundNumber);
      formData.append("language", "de");
      formData.append("file", csvFile);
      formData.append(
        "pronunciation_guide",
        JSON.stringify(pronunciationGuide)
      );
      formData.append("user_id", user.userId);
      formData.append("transfer_list", JSON.stringify(transferList));

      if (scheduleType === "schedule" && startTime) {
        formData.append("start_time", `${startTime} ${timeZone}`);
      }

      formData.append("retry", JSON.stringify(retry));

      if (notesEmailEnabled) {
        formData.append("company_email", companyEmail);
      }

      // Add SMS details if SMS is enabled
      formData.append("sms_enable", sms.enabled.toString());
      if (sms.enabled) {
        formData.append("sms_text", sms.message);
      }

      // Add Email details if Email is enabled
      formData.append("email_enable", email.enabled.toString());
      if (email.enabled) {
        formData.append("email_text", email.message);
      }

      // Add Calendly details if Calendly is enabled
      formData.append("calendar_enable", calendar.enabled.toString());
      if (calendar.enabled) {
        formData.append(
          "check_slots_webhook",
          integrations.check_slots_webhook
        );
        formData.append("book_slot_webhook", integrations.book_slot_webhook);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/upload-csv-and-send-batch`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success("Campaign started successfully!");
    } catch (error) {
      console.error("Error during campaign start:", error);
      const errorMessage =
        error.response?.data?.message || "Campaign in Process.";
      message.error({
        content: errorMessage,
        icon: <CheckCircleOutlined style={{ color: "#12e640" }} />, // Custom icon for error messages
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSmsToggle = (checked) => {
    if (checked && !integrations.twilio_encrypted_key) {
      message.error("Please integrate SMS service before enabling.");
      return;
    }
    setSms((prev) => ({ ...prev, enabled: checked }));
  };

  const handleEmailToggle = (checked) => {
    if (checked && !integrations.resend_api_key) {
      message.error("Please integrate Email service before enabling.");
      return;
    }
    setEmail((prev) => ({ ...prev, enabled: checked }));
  };

  const handleCalendarToggle = (checked) => {
    if (!checked) return;

    if (!integrations.check_slots_webhook || !integrations.book_slot_webhook) {
      message.error("Please integrate Calendar service before enabling.");
      return;
    }
    setCalendar((prev) => ({ ...prev, enabled: checked }));
  };

  const handleNotesEmailToggle = (checked) => {
    if (checked && !integrations.resend_api_key) {
      message.error("Please integrate Email service before enabling.");
      return;
    }
    setNotesEmailEnabled(checked);
  };

  return (
    <div
      style={{
        width: "90%",
        marginBottom: "100px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>{t("startCampaign")}</h1>
        <Button
          type="primary"
          onClick={startCampaign}
          style={{
            padding: "20px 30px",
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <span style={{ fontSize: "15px" }}>{t("start")}</span>
          <ArrowRightOutlined />
        </Button>
      </div>
      <Form
        layout="vertical"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "10px",
        }}
      >
        <Form.Item
          label={
            <span>
              {t("uploadFile") + " CSV"}{" "}
              <Tooltip title={t("uploadFileTooltip")}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
        >
          <UploadCSV setCsvData={setCsvData} setCsvFile={setFile} />
        </Form.Item>
        <Form.Item
          label={
            <span>
              {t("campaignId")}{" "}
              <Tooltip title={t("campaignIdTooltip")}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          required
        >
          <Input
            placeholder={t("enterCampaignId")}
            value={campaignId}
            onChange={(e) => setCampaignId(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              {t("selectScript")}{" "}
              <Tooltip title={t("selectScriptTooltip")}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          required
        >
          <Select
            style={{ width: "100%" }}
            placeholder={t("selectScript")}
            value={selectedScript ? selectedScript._id : null}
            onChange={handleScriptChange}
          >
            {scripts.map((script) => (
              <Option key={script._id} value={script._id}>
                {script.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              {t("firstSentence")}{" "}
              <Tooltip title={t("firstSentenceTooltip")}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          required
        >
          <Input
            placeholder={t("enterFirstSentence")}
            value={firstSentence}
            onChange={(e) => setFirstSentence(e.target.value)}
          />
        </Form.Item>

        {/* <Form.Item
          label={
            <span>
              {t("selectVoice")}{" "}
              <Tooltip
                title={t(
                  "Choose a voice for the AI agent."
                )}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          required
        >
          <Select
            style={{ width: "100%" }}
            placeholder={t("selectVoice")}
            value={selectedVoice}
            onChange={(value) => setSelectedVoice(value)}
          >
            {availableVoices.map((voice) => (
              <Option key={voice.id} value={voice.id}>
                {voice.name} - {voice.description}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

        <Form.Item
          label={
            <span>
              {t("selectCategory")}{" "}
              <Tooltip title={t("selectCategoryTitle")}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          required
        >
          <Select
            style={{ width: "100%" }}
            placeholder={t("selectCategory")}
            value={voiceCategory}
            onChange={(value) => setVoiceCategory(value)}
          >
            <Option value="normal">{t("normalVoices")}</Option>
            <Option value="premium">{t("premiumVoices")}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              {t("selectVoice")}{" "}
              <Tooltip title={t("selectVoiceTitle")}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          required
        >
          <Select
            style={{ width: "100%" }}
            placeholder={t("selectVoice")}
            value={selectedVoice}
            onChange={(value) => setSelectedVoice(value)}
          >
            {(voiceCategory === "normal" ? normalVoices : premiumVoices).map(
              (voice) => (
                <Option
                  key={voice._id || voice.id}
                  value={voice._id || voice.id}
                >
                  {voice.name} - {voice.description}
                </Option>
              )
            )}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              {t("selectOutboundNumber")}{" "}
              <Tooltip title={t("outboundNumberTooltip")}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          required
        >
          <Select
            style={{ width: "100%" }}
            placeholder={t("selectOutboundNumber")}
            value={outboundNumber}
            onChange={(value) => setOutboundNumber(value)}
          >
            {outboundNumbers.map((number) => (
              <Option key={number.phone_number} value={number.phone_number}>
                {number.phone_number} (Last Used:{" "}
                {moment(number.last_initiated).format("YYYY-MM-DD HH:mm:ss")})
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              {t("callScheduling")}{" "}
              <Tooltip title={t("callSchedulingTitle")}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          required
        >
          <Radio.Group
            onChange={(e) => setScheduleType(e.target.value)}
            value={scheduleType}
          >
            <Radio value="immediate">{t("callImmediately")}</Radio>
            <Radio value="schedule">{t("scheduleCall")}</Radio>
          </Radio.Group>
        </Form.Item>

        {scheduleType === "schedule" && (
          <>
            <Form.Item
              label={
                <span>
                  {t("selectDate")}{" "}
                  <Tooltip title={t("selectDateTooltip")}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              }
              required
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t("selectDate")} // Placeholder for date picker
                onChange={(date, dateString) => {
                  setStartTime((prev) =>
                    dateString
                      ? `${dateString} ${prev?.split(" ")[1] || ""}`
                      : prev
                  );
                }}
              />
            </Form.Item>

            <Form.Item
              label={
                <span>
                  {t("selectTime")}{" "}
                  <Tooltip title={t("selectTimeTooltip")}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              }
              required
            >
              <TimePicker
                style={{ width: "100%" }}
                placeholder={t("selectTime")} // Placeholder for time picker
                onChange={(time, timeString) => {
                  setStartTime((prev) =>
                    timeString
                      ? `${prev?.split(" ")[0] || ""} ${timeString}`
                      : prev
                  );
                }}
              />
            </Form.Item>

            <Form.Item
              label={
                <span>
                  {t("selectTimeZone")}{" "}
                  <Tooltip title={t("selectTimeZoneTooltip")}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              }
              required
            >
              <Select
                style={{ width: "100%" }}
                placeholder={t("selectTimeZone")}
                onChange={(value) => setTimeZone(value)}
              >
                {timeZones.map((tz) => (
                  <Option key={tz.tzCode} value={tz.tzCode}>
                    {tz.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        <Form.Item
          label={
            <span>
              {t("pronunciationGuide")}{" "}
              <Tooltip title={t("pronunciationGuideTooltip")}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
        >
          <PronunciationGuideInput
            value={pronunciationGuide}
            onChange={setPronunciationGuide}
          />
        </Form.Item>

        <Form.Item
          label={
            <span>
              {t("transferList")}{" "}
              <Tooltip title={t("transferListTooltip")}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
        >
          <TransferListInput value={transferList} onChange={setTransferList} />
        </Form.Item>

        <Form.Item
          label={
            <span>
              {t("retryConfiguration")}{" "}
              <Tooltip title={t("retryConfigurationTooltip")}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
        >
          <RetryConfigurationInput value={retry} onChange={setRetry} />
        </Form.Item>

        <div style={{ marginTop: "20px" }}>
          <h3 style={{ marginBottom: "20px", textAlign: "center" }}>
            {t("postCallActions")}
          </h3>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Form.Item
              label={
                <span
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {t("sms")}{" "}
                  <Tooltip title={t("smsLabel")}>
                    <InfoCircleOutlined />
                  </Tooltip>
                  <Switch onClick={handleSmsToggle} checked={sms.enabled} />
                </span>
              }
            >
              {sms.enabled && (
                <Input
                  placeholder={t("smsMessagePlaceholder")}
                  value={sms.message}
                  onChange={(e) =>
                    setSms((prev) => ({ ...prev, message: e.target.value }))
                  }
                />
              )}
            </Form.Item>

            <Form.Item
              label={
                <span
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {t("email")}{" "}
                  <Tooltip title={t("emailLabel")}>
                    <InfoCircleOutlined />
                  </Tooltip>
                  <Switch onClick={handleEmailToggle} checked={email.enabled} />
                </span>
              }
            >
              {email.enabled && (
                <Input
                  placeholder={t("emailMessagePlaceholder")}
                  value={email.message}
                  onChange={(e) =>
                    setEmail((prev) => ({ ...prev, message: e.target.value }))
                  }
                />
              )}
            </Form.Item>

            <Form.Item
              label={
                <span
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {t("enableAppointmentSetting")}{" "}
                  <Tooltip
                    title={t(
                      "Enable Calendly integration to book appointments directly from the call."
                    )}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                  <Switch
                    onClick={handleCalendarToggle}
                    checked={calendar.enabled}
                  />
                </span>
              }
            >
              {/* Optionally add Calendly-specific inputs here */}
            </Form.Item>

            <Form.Item
              label={
                <span
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {t("enableNotesEmailing")}{" "}
                  <Tooltip title={t("enableNotesEmailingTooltip")}>
                    <InfoCircleOutlined />
                  </Tooltip>
                  <Switch
                    onClick={handleNotesEmailToggle}
                    checked={notesEmailEnabled}
                  />
                </span>
              }
            >
              {notesEmailEnabled && (
                <Input
                  placeholder={t("enterCompanyEmail")}
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                />
              )}
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default StartCampaign;
