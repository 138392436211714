import React, { useState } from "react";
import { Button, Form, Input, Alert, Layout, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import bgImage from "../assets/bg.jpg"; // Adjust the path if needed

const { Content, Sider } = Layout;
const { Title } = Typography;
const path = process.env.REACT_APP_API_PATH;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${path}/forgot_password`, {
        email,
      });
      setSuccess("Password reset email sent! Please check your inbox.");
    } catch (error) {
      setError(error.response?.data?.error || "Failed to send reset email");
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
      }}
    >
      <Sider theme="dark">
        {/* Side menu or additional components can go here */}
      </Sider>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: 400,
            padding: "24px",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
          }}
        >
          {error && (
            <Alert
              message={error}
              type="error"
              showIcon
              closable
              afterClose={() => setError("")}
            />
          )}
          {success && (
            <Alert
              message={success}
              type="success"
              showIcon
              closable
              afterClose={() => setSuccess("")}
            />
          )}
          <Form layout="vertical" onFinish={handleSubmit}>
            <Title level={3} style={{ color: "black", textAlign: "center" }}>
              FORGOT PASSWORD
            </Title>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Send Reset Email
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="default" block onClick={handleLogin}>
                Back to Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </Layout>
  );
};

export default ForgotPassword;
