import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Button, Form, Input, Alert, Layout, Typography } from 'antd';
import bgImage from '../assets/bg.jpg';

const { Content, Sider } = Layout;
const { Title } = Typography;
const path = process.env.REACT_APP_API_PATH;

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [resetToken, setResetToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    const tokenParam = queryParams.get('token');

    if (emailParam && tokenParam) {
      setEmail(emailParam);
      setResetToken(tokenParam);
    } else {
      setError('Invalid password reset link');
    }
  }, [location]);

  const handleSubmit = async () => {
    if (newPassword !== confirmNewPassword) {
      setError('Passwords do not match!');
      return;
    }

    try {
      await axios.post(`${path}/reset_password`, {
        email,
        reset_token: resetToken,
        new_password: newPassword,
      });
      setSuccess('Password reset successfully!');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      setError(error.response?.data?.error || 'Password reset failed');
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
      }}
    >
      <Sider theme="dark">{/* Optional Sider content */}</Sider>
      <Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '24px',
        }}
      >
        <div
          style={{
            width: '100%',
            maxWidth: 400,
            padding: '24px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          }}
        >
          {error && (
            <Alert
              message={error}
              type="error"
              showIcon
              closable
              afterClose={() => setError('')}
            />
          )}
          {success && (
            <Alert
              message={success}
              type="success"
              showIcon
              closable
              afterClose={() => setSuccess('')}
            />
          )}
          <Form layout="vertical" onFinish={handleSubmit}>
            <Title level={3} style={{ color: 'black', textAlign: 'center' }}>
              RESET PASSWORD
            </Title>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                { required: true, message: 'Please input your new password!' },
              ]}
            >
              <Input.Password
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirmNewPassword"
              rules={[
                {
                  required: true,
                  message: 'Please confirm your new password!',
                },
              ]}
            >
              <Input.Password
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Reset Password
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="default" block onClick={handleLogin}>
                Back to Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </Layout>
  );
};

export default ResetPassword;
