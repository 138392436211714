import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Select, message, Form } from "antd";
import { InfoCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import axios from "axios";
import { BlandWebClient } from "bland-client-js-sdk";
import { useTranslation } from "react-i18next";
const { TextArea } = Input;

const path = process.env.REACT_APP_API_PATH;

export default function AddScript({ userId, onSuccess }) {
  const { t } = useTranslation();
  const [modalType, setModalType] = useState("add"); // 'see', 'add', 'edit', or 'show'
  const [availableScripts, setAvailableScripts] = useState([]);
  const [selectedScriptId, setSelectedScriptId] = useState(null);
  const [scriptName, setScriptName] = useState("");
  const [greeting, setGreeting] = useState("");
  const [companyDetails, setCompanyDetails] = useState({
    address: "",
    homepage: "",
    email: "",
    phoneNumber: "",
    contactPerson: "",
  });
  const [productDetails, setProductDetails] = useState({
    description: "",
    client: "",
    sellingMethod: "",
    usps: "",
    advantages: "",
    specialFeatures: "",
  });
  const [aiAgentDetails, setAiAgentDetails] = useState({
    character: "",
    talkingSpeed: "",
    tone: "",
    voiceSound: "",
    importantEmotions: "",
  });
  const [clientObjections, setClientObjections] = useState("");
  const [structuredContent, setStructuredContent] = useState("");
  const [isShowScriptModalVisible, setIsShowScriptModalVisible] =
    useState(false);
  const [availableVoices, setAvailableVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState("");
  const [isConversationRunning, setIsConversationRunning] = useState(false);
  const [blandClient, setBlandClient] = useState(null);

  // useEffect(() => {
  //   if (modalType === "see") {
  //     fetchScripts();
  //     fetchVoices();
  //   }
  // }, [modalType]);

  const fetchScripts = async () => {
    try {
      const response = await axios.get(`${path}/user_scripts`, {
        params: { user_id: userId },
      });
      setAvailableScripts(response.data);
      message.success("Scripts fetched successfully!");
    } catch (error) {
      console.error("Error fetching scripts:", error);
      message.error("Failed to fetch scripts.");
    }
  };

  const fetchVoices = async () => {
    try {
      const response = await axios.get(`${path}/voices`);
      const voices = response.data.voices || [];

      const germanVoices = voices.filter((voice) => {
        const description = voice.description || "";
        const tags = voice.tags || [];

        const isGermanTag = tags.some((tag) =>
          tag.toLowerCase().includes("german")
        );
        const isGermanDescription = description
          .toLowerCase()
          .includes("german");

        const commonGermanWords = [
          "und",
          "der",
          "die",
          "das",
          "ist",
          "nicht",
          "ein",
          "eine",
          "mit",
          "auf",
          "für",
          "von",
          "zu",
          "haben",
        ];
        const isDescriptionInGerman = commonGermanWords.some((word) =>
          description.toLowerCase().includes(word)
        );

        return isGermanTag || isGermanDescription || isDescriptionInGerman;
      });

      setAvailableVoices(germanVoices);
      message.success("German voices fetched successfully!");
    } catch (error) {
      message.error("Failed to fetch voices: " + error.message);
    }
  };

  const handleSaveScript = async () => {
    console.log("Saving script...");
    if (!scriptName) {
      message.error("Please provide a script name.");
      return;
    }

    const generatedScript = {
      name: scriptName,
      greeting: greeting,
      user_id: userId,
      companyDetails: companyDetails,
      productDetails: productDetails,
      aiAgentDetails: aiAgentDetails,
      clientObjections: clientObjections,
      structured_content: structuredContent,
    };

    try {
      const hide = message.loading({
        content: "Saving data, generating your script...",
        duration: 0,
      });

      if (modalType === "add") {
        await axios.post(`${path}/user_script`, generatedScript);
        message.success("Script added successfully!");
        onSuccess();
      } else if (modalType === "edit") {
        await axios.put(`${path}/user_script`, {
          ...generatedScript,
          script_id: selectedScriptId,
        });
        message.success("Script updated successfully!");
      }

      hide();
      fetchScripts();
      // setShowScriptModal(false);
    } catch (error) {
      message.error("Failed to save script: " + error.message);
    }
  };

  const handleSaveStructuredScript = async () => {
    if (!selectedScriptId || !structuredContent) {
      message.error("Script ID and structured content are required.");
      return;
    }

    try {
      const hide = message.loading({
        content: "Saving script content...",
        duration: 0,
      });

      await axios.put(`${path}/update-script`, {
        script_id: selectedScriptId,
        structured_content: structuredContent,
      });

      message.success("Structured script content updated successfully!");
      setIsShowScriptModalVisible(false);
      hide();
      fetchScripts();
    } catch (error) {
      message.error("Failed to update script content: " + error.message);
    }
  };

  const handleDeleteScript = async () => {
    try {
      await axios.delete(`${path}/user_script`, {
        data: { script_id: selectedScriptId },
      });
      message.success("Script deleted successfully!");
      fetchScripts();
    } catch (error) {
      message.error("Failed to delete script: " + error.message);
    }
  };

  const handleAIUpgrade = async () => {
    if (!selectedScriptId) {
      message.error("No script selected.");
      return;
    }

    const hide = message.loading({
      content: "AI is generating your script...",
      duration: 0,
    });

    const upgradeData = {
      script_id: selectedScriptId,
      user_id: userId,
      greeting: greeting,
      companyDetails: companyDetails,
      productDetails: productDetails,
      aiAgentDetails: aiAgentDetails,
      clientObjections: clientObjections,
    };

    try {
      const response = await axios.post(
        `${path}/ai_upgrade_script`,
        upgradeData
      );
      const upgradedContent = response.data.content;

      setStructuredContent(upgradedContent);
      message.success("Script upgraded successfully!");
    } catch (error) {
      message.error("Failed to upgrade script: " + error.message);
    } finally {
      hide();
    }
  };

  const handleShowScript = () => {
    if (!structuredContent) {
      message.error("No structured content available.");
      return;
    }

    setIsShowScriptModalVisible(true);
  };

  const handleTestScript = async () => {
    if (!selectedVoice) {
      message.warning("Please select a voice before testing the script.");
      return;
    }

    try {
      const agentId = "fe83ed95-47e0-466f-b216-487fafc3441a";

      await axios.post(`${path}/update-agent`, {
        agent_id: agentId,
        script_id: selectedScriptId,
        voice: selectedVoice,
        language: "GER",
      });

      const authorizeResponse = await axios.post(`${path}/authorize-agent`, {
        agentId: agentId,
      });
      const sessionToken = authorizeResponse.data.token;

      const client = new BlandWebClient(agentId, sessionToken);

      await client.initConversation({
        sampleRate: 44100,
      });

      setIsConversationRunning(true);
      setBlandClient(client);

      setTimeout(() => {
        message.success("Conversation initialized successfully!");
      }, 5000);
    } catch (error) {
      console.error("Error during test script:", error);
      message.error("Failed to test the script.");
    }
  };

  const handleStopConversation = async () => {
    try {
      if (blandClient) {
        await blandClient.stopConversation(); // Stop the conversation
        message.success("Conversation stopped successfully.");
        setIsConversationRunning(false);
        setBlandClient(null);
      }
    } catch (error) {
      console.error("Error stopping the conversation:", error);
      message.error("Failed to stop the conversation.");
    }
  };

  const handleScriptSelection = (scriptId) => {
    const script = availableScripts.find((s) => s._id === scriptId);
    if (script) {
      setSelectedScriptId(script._id);
      setScriptName(script.name);
      setGreeting(script.greeting);
      setCompanyDetails(script.company_details);
      setProductDetails(script.product_details);
      setAiAgentDetails(script.ai_agent_details);
      setClientObjections(script.client_objections);
      setStructuredContent(script.structured_content);
    }
  };

  return (
    <div style={{ width: "70%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="mb-3">{t("addScriptDetails")}</h1>
        <Button
          type="primary"
          onClick={handleSaveScript}
          style={{
            padding: "20px 30px",
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {t("saveScript")}
          <ArrowRightOutlined />
        </Button>
      </div>
      <Form
        layout="vertical"
        style={
          {
            // display: "grid",
            // gridTemplateColumns: "1fr 1fr",
            // gap: "10px",
          }
        }
      >
        <div>
          <Form.Item label={t("scriptName")}>
            <Input
              value={scriptName}
              onChange={(e) => setScriptName(e.target.value)}
              placeholder={t("enterScriptName")}
            />
          </Form.Item>
          <Form.Item label={t("greetingSentence")}>
            <Input
              value={greeting}
              onChange={(e) => setGreeting(e.target.value)}
              placeholder={t("enterGreetingSentence")}
            />
          </Form.Item>
        </div>
        <h3 className="mb-3">{t("companyDetails")}</h3>
        <Form.Item label={t("address")}>
          <Input
            value={companyDetails.address}
            onChange={(e) =>
              setCompanyDetails({
                ...companyDetails,
                address: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label={t("homepage")}>
          <Input
            value={companyDetails.homepage}
            onChange={(e) =>
              setCompanyDetails({
                ...companyDetails,
                homepage: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label={t("email")}>
          <Input
            type="email"
            value={companyDetails.email}
            onChange={(e) =>
              setCompanyDetails({ ...companyDetails, email: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label={t("phoneNumber")}>
          <Input
            type="tel"
            value={companyDetails.phoneNumber}
            onChange={(e) =>
              setCompanyDetails({
                ...companyDetails,
                phoneNumber: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label={t("physicalPerson")}>
          <Input
            value={companyDetails.contactPerson}
            onChange={(e) =>
              setCompanyDetails({
                ...companyDetails,
                contactPerson: e.target.value,
              })
            }
          />
        </Form.Item>

        <h3 className="mb-3">{t("productDetails")}</h3>
        <Form.Item label={t("description")}>
          <Input
            value={productDetails.description}
            onChange={(e) =>
              setProductDetails({
                ...productDetails,
                description: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label={t("client")}>
          <Input
            value={productDetails.client}
            onChange={(e) =>
              setProductDetails({ ...productDetails, client: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label={t("sellingMethod")}>
          <Input
            value={productDetails.sellingMethod}
            onChange={(e) =>
              setProductDetails({
                ...productDetails,
                sellingMethod: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label={t("usps")}>
          <Input
            value={productDetails.usps}
            onChange={(e) =>
              setProductDetails({ ...productDetails, usps: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label={t("advantages")}>
          <Input
            value={productDetails.advantages}
            onChange={(e) =>
              setProductDetails({
                ...productDetails,
                advantages: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label={t("specialFeatures")}>
          <Input
            value={productDetails.specialFeatures}
            onChange={(e) =>
              setProductDetails({
                ...productDetails,
                specialFeatures: e.target.value,
              })
            }
          />
        </Form.Item>

        <h3 className="mb-3">AI Agent</h3>
        <Form.Item label={t("character")}>
          <Input
            value={aiAgentDetails.character}
            onChange={(e) =>
              setAiAgentDetails({
                ...aiAgentDetails,
                character: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label={t("talkingSpeed")}>
          <Input
            value={aiAgentDetails.talkingSpeed}
            onChange={(e) =>
              setAiAgentDetails({
                ...aiAgentDetails,
                talkingSpeed: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label={t("tone")}>
          <Input
            value={aiAgentDetails.tone}
            onChange={(e) =>
              setAiAgentDetails({ ...aiAgentDetails, tone: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label={t("voiceSound")}>
          <Input
            value={aiAgentDetails.voiceSound}
            onChange={(e) =>
              setAiAgentDetails({
                ...aiAgentDetails,
                voiceSound: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item label={t("importantEmotions")}>
          <Input
            value={aiAgentDetails.importantEmotions}
            onChange={(e) =>
              setAiAgentDetails({
                ...aiAgentDetails,
                importantEmotions: e.target.value,
              })
            }
          />
        </Form.Item>

        <h3 className="mb-3">{t("clientObjections")}</h3>
        <Form.Item label={t("clientObjections")}>
          <TextArea
            rows={4}
            value={clientObjections}
            onChange={(e) => setClientObjections(e.target.value)}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
