import React, { useState, useEffect } from "react";
import { Form, Input, Button, Alert, Table } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/AuthContext";

const PurchaseNumber = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [twilioIntegrated, setTwilioIntegrated] = useState(false);
  const [twilioNumbers, setTwilioNumbers] = useState([]);
  const [newTwilioNumber, setNewTwilioNumber] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const path = process.env.REACT_APP_API_PATH;

  useEffect(() => {
    const checkTwilioIntegration = async () => {
      try {
        const response = await axios.get(`${path}/check-twilio-integration`, {
          params: { user_id: user.userId },
        });
        setTwilioIntegrated(response.data.integrated);
        setTwilioNumbers(response.data.numbers || []);
      } catch (error) {
        setMessage("Error checking Twilio integration.");
      } finally {
        setLoading(false);
      }
    };

    checkTwilioIntegration();
  }, [user.userId, path]);

  const handleConnectNumber = async () => {
    try {
      const response = await axios.post(`${path}/connect-twilio-number`, {
        twilio_number: newTwilioNumber,
        user_id: user.userId, // Send the user ID to the backend
      });

      if (response.data.number) {
        setMessage(
          `Twilio number ${response.data.number} connected successfully!`
        );
        setTwilioNumbers([...twilioNumbers, response.data.number]);
        setNewTwilioNumber("");
      } else {
        setMessage("Failed to connect Twilio number.");
      }
    } catch (error) {
      setMessage("Error connecting Twilio number.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>{t("purchaseNumber")}</h2>
      {message && <Alert message={message} type="info" showIcon />}
      {!twilioIntegrated ? (
        <Alert
          message="Twilio is not integrated. Please integrate it from the Integrations tab."
          type="warning"
          showIcon
        />
      ) : (
        <>
          <Table
            dataSource={twilioNumbers.map((number, index) => ({
              key: index,
              number,
            }))}
            columns={[
              {
                title: "Twilio Numbers",
                dataIndex: "number",
                key: "number",
              },
            ]}
          />
          <Form layout="vertical" style={{ marginTop: 20 }}>
            <Form.Item label={t("newTwilioNumber")}>
              <Input
                value={newTwilioNumber}
                onChange={(e) => setNewTwilioNumber(e.target.value)}
              />
            </Form.Item>
            <Button type="primary" onClick={handleConnectNumber}>
              {t("connectNumber")}
            </Button>
          </Form>
        </>
      )}
    </div>
  );
};

export default PurchaseNumber;
