// src/i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// Translations
const resources = {
  en: {
    translation: {
      // Existing keys
      welcome: "Welcome to Your Dashboard",
      welcomeMessage:
        "Welcome to the Campaign Management Dashboard. Here you can effortlessly manage your campaigns, upload CSV files to start new campaigns, and monitor your analytics to make data-driven decisions. Let's get started!",
      uploadFile: "Upload",
      addScript: "Add Script",
      testScript: "Test Script",
      addScriptDetails: "Add Script Details",
      scriptDetails: "Script Details",
      editScript: "Edit Script",
      selectScript: "Select Script",
      deleteScript: "Delete Script",
      aiUpgrade: "AI Upgrade",
      showScript: "Show Script",
      manageScripts: "Manage Scripts",
      startCampaign: "Start Campaign",
      start: "Start",
      campaignHistory: "Campaign History",
      getAllBatches: "Get All Batches",
      batchDetail: "Batch Details",
      close: "Close",
      upgradeToPro: "Upgrade to Pro",
      integrations: "Integrations",
      userProfile: "User Profile",
      purchaseNumber: "Purchase Number",
      logout: "Logout",
      campaignId: "Campaign ID",
      createdAt: "Created At",
      totalCall: "Total number of calls",
      completedCall: "Completed Calls",
      inProgressCall: "In Progress Calls",
      callDetails: "Call Details",
      noCallDetails: "No details available",
      status: "Status",
      details: "Details",
      analyze: "Generate Analysis",
      stopCampaign: "Stop Campaign",
      enterCampaignId: "Enter Campaign ID",
      enterGreetings: "Enter Greetings",
      firstSentence: "Enter the first sentence of the call script",
      selectLanguage: "Select Language",
      selectVoice: "Select a Voice",
      selectVoiceTitle: "Choose a voice for the AI agent.",
      scheduleType: "Select Schedule Type",
      startDate: "Select Start Date",
      startTime: "Select Start Time",
      selectTimeZone: "Select Time Zone",
      callsPerHour: "Enter Calls Per Hour",
      cancel: "Cancel",
      save: "Save",
      useDefault: "Use Default",
      inboundCallManagement: "Inbound Call Management",
      inbound: "Inbound",
      assignNumbers: "Assign Numbers",
      assignNumbersToAgent: "Assign Numbers to Agent",
      setAgent: "Set Agent",
      analytics: "Analytics",
      sms: "SMS",
      smsLabel: "Send SMS after call",
      email: "Email",
      emailLabel: "Send email after call",
      emailMessagePlaceholder: "Enter email message",
      integrateTwilio: "Integrate Twilio",
      integrateResend: "Integrate Resend",
      integrate: "Integrate",
      integrateCalendly: "Integrate Calendly",
      saveScript: "Save Script",
      scriptName: "Script Name",
      enterScriptName: "Enter Script Name",
      greetingSentence: "Greeting Sentence",
      enterGreetingSentence: "Enter Greeting Sentence",
      companyDetails: "Company Details",
      address: "Address",
      homepage: "Homepage",
      phoneNumber: "Phone Number",
      clientObjections: "Client Objections",
      modifyScript: "Modify Script",
      selectScriptTooltip: "Choose the script to use for this campaign.",
      firstSentenceTooltip: "The initial sentence to be spoken by the voice.",
      outboundNumberTooltip: "Choose the outbound number for the calls.",
      callSchedulingTooltip: "Choose when the call should be placed.",
      selectDateTooltip: "Select the date for the scheduled call.",
      selectTimeTooltip: "Select the time for the scheduled call.",
      selectTimeZoneTooltip: "Select the time zone for the scheduled call.",
      uploadFileTooltip:
        "Upload a CSV file containing the data to start your campaign.",
      campaignIdTooltip:
        "Unique identifier for your campaign. Ensure this ID is unique.",
      voiceTooltip:
        "Choose a voice for the AI agent. Default options include: Maya, Mason, Ryan, Adriana, Tina, Matt, Evelyn.",
      pronunciationGuideTooltip:
        "Provide specific pronunciations for certain words or phrases.",
      transferListTooltip:
        "List of departments and their corresponding phone numbers for call transfers.",
      retryConfigurationTooltip:
        "Configure retry settings for calls that reach voicemail.",
      smsTooltip: "Enable sending SMS after the call ends.",
      emailTooltip: "Enable sending an email after the call ends.",
      calendlyTooltip:
        "Enable Calendly integration to book appointments directly from the call.",
      pronunciationWord: "Word",
      pronunciationWordTooltip: "The word that needs pronunciation guidance.",
      pronunciation: "Pronunciation",
      pronunciationTooltip: "How the word should be pronounced.",
      caseSensitive: "Case Sensitive",
      caseSensitiveTooltip: "Should the word be treated as case-sensitive?",
      spaced: "Spaced",
      spacedTooltip: "Should the pronunciation be spaced out?",
      remove: "Remove",
      addPronunciation: "Add Pronunciation",
      department: "Department",
      addTransfer: "Add Transfer",
      waitTime: "Wait Time (seconds)",
      waitTimeTooltip:
        "The delay before retrying the call in case of a voicemail.",
      voicemailAction: "Voicemail Action",
      voicemailActionTooltip: "Action to take when the call reaches voicemail.",
      voicemailMessage: "Voicemail Message",
      voicemailMessageTooltip: "The message to leave if voicemail is reached.",
      callImmediately: "Call Immediately",
      scheduleCall: "Schedule Call",
      enableAppointmentSetting: "Enable Appointment Setting",
      enableNotesEmailing: "Enable Notes Emailing",
      enableNotesEmailingTooltip:
        "Enable AI agent to email notes to the company email after the call.",
      enterCompanyEmail: "Enter Company Email",
      webhookIntegration: "Webhook Integration",
      webhookIntegrationTooltip:
        "Enable integration with a webhook for real-time data transfer.",
      aiUpgradingTooltip:
        "Use AI to enhance the script's content automatically.",
      // Added keys
      selectCategory: "Select Category",
      selectCategoryTitle: "Choose between normal and premium voices.",
      normalVoices: "Normal Voices",
      premiumVoices: "Premium Voices",
      pronunciationGuide: "Pronunciation Guide",
      transferList: "Transfer List",
      retryConfiguration: "Retry Configuration",
      postCallActions: "Post-Call Actions",
      language: "Language",
      home: "Home",
      failedToFetchNumbers: "Failed to fetch numbers.",
      analysisDataFetched: "Analysis data fetched successfully!",
      failedToFetchAnalysisData: "Failed to fetch analysis data.",
      callDetailsFetched: "Call details fetched successfully!",
      failedToFetchCallDetails: "Failed to fetch call details.",
      totalPrice: "Total Price",
      topCaller: "Top Caller",
      noData: "No Data",
      count: "Count",
      totalDuration: "Total Duration",
      seconds: "seconds",
      averageDuration: "Average Duration",
      distributionByTime: "Distribution by Time",
      morning: "Morning",
      afternoon: "Afternoon",
      evening: "Evening",
      night: "Night",
      callEndedBy: "Call Ended By",
      assistant: "Assistant",
      user: "User",
      callId: "Call ID",
      from: "From",
      to: "To",
      duration: "Duration",
      completed: "Completed",
      yes: "Yes",
      no: "No",
      endAt: "End At",
      price: "Price",
      summary: "Summary",
      open: "Open",
      callSummary: "Call Summary",
      createAgent: "Create Agent",
      saveAgent: "Save Agent",
      enterAgentName: "Enter Agent Name",
      integrateSmsService: "Please integrate SMS service before enabling.",
      enterSmsMessage: "Enter SMS message",
      enableEmailTooltip: "Enable sending an email after the call ends.",
      integrateEmailService: "Please integrate Email service before enabling.",
      enterEmailMessage: "Enter Email message",
      companyEmailTooltip: "Provide the company email for AI emailing.",
      enableCalendlyTooltip: "Enable appointment setting via Calendly.",
      integrateCalendlyService:
        "Please integrate Calendly service before enabling.",
      aiUpgradeScript: "AI Upgrade Script",
      selectNumberFirst: "Please select a phone number first.",
      failedToFetchAgents: "Failed to fetch agents.",
      integrateSmsService: "Please integrate SMS service before enabling.",
      integrateEmailService: "Please integrate Email service before enabling.",
      failedToFetchScripts: "Failed to fetch scripts.",
      failedToFetchVoices: "Failed to fetch voices.",
      numbersFetched: "Phone numbers fetched successfully!",
      selectPhoneNumbers: "Select Phone Numbers",
      aiUpgrade: "AI Upgrade",
      loggedOut: "You have been logged out.",
      contactSupport: "Contact Support",
      selectOutboundNumber: "Select Outbound Number",
      selectDate: "Select Date",
      selectTime: "Select Time",
      selectTimeZone: "Select Time Zone",
      fileUploadedSuccessfully: "File uploaded successfully",
      errorProcessingFile: "Error processing file",
      errorUploadingFile: "Error uploading file",
      failedToFetchPrompts: "Failed to fetch prompts.",
      failedToFetchIntegrations: "Failed to fetch user integrations.",
      agentManagement: "Agent Management",
      campaign: "Campaign",
      user: "User",
      help: "Help",
      phoneNumberManagement: "Phone Number Management",
      contactSupport: "Contact Support",
      manageAgent: "Manage Agent",
      language: "Language",
      upgradeToPro: "Upgrade to Pro ",
      addAgent: "Add Agent",
      home: "Home",
      FAQs: "FAQs",
      selectNumber: "Select a number",
      physicalPerson: "Physical person contact details",
      physical: "Physical person",
      productDetails: "Product Details",
      description: "Description",
      client: "Client",
      sellingMethod: "Selling Method",
      usps: "USPs",
      advantages: "Advantages",
      specialFeatures: "Special Features",
      aiAgent: "AI Agent",
      character: "Character",
      talkingSpeed: "Talking Speed",
      tone: "Tone",
      voiceSound: "Voice Sound",
      importantEmotions: "Important Emotions",
      nofilechosen: "No file chosen",
      Choosefile: "Choose file",
      firstSentence: "First Sentence",
      enterFirstSentence: "Enter First Sentence",
      selectOutboundNumber: "Select Outbound Number",
      callImmediately: "Call Immediately",
      scheduleCall: "Schedule Call",
      callScheduling: "Call Scheduling",
      callSchedulingTitle:
        "Schedule when the call should take place. Calls can be immediate or scheduled for later.",
      pronunciationGuide: "Pronunciation Guide",
      transferList: "Transfer List",
      addTransfer: "Add Transfer",
      retryConfiguration: "Retry Configuration",
      waitTimeMinutes: "Wait Time (minutes)",
      enableNotesEmailing: "Enable Notes Emailing",
      name: "Name",
      subscriptionStatus: "Subscription Status",
      callLimit: "Call Limit",
      generateAndDownloadInvoice: "Generate and Download Invoice",
      cancelSubscription: "Cancel Subscription",
      deleteAccount: "Delete Account",
      backToHomepage: "Back to Homepage",
      back: "Back",
      integrateCRM: "Integrate CRM",
      disconnectCRM: "Disconnect CRM",
      frequentlyAskedQuestions: "Frequently Asked Questions",
      twilioNumbers: "Twilio Numbers",
      newTwilioNumber: "New Twilio Number",
      connectNumber: "Connect Number",
      leads: "Leads",
      backToOutbound: "Back to Outbound",
      alreadySubscribed: "Already Subscribed",
      month: "Month",
      smsMessagePlaceholder: "Enter sms message text.",
      selectAction: "Select Action",
      ignore: "Ignore",
      leaveMessage: "Leave Message",
      hangup: "Hangup",
      enterWaitTimeMinutes: "Enter wait time in minutes",
      smsMessagePlaceholder: "Enter SMS message text.",
      chooseYourRightPlan: "Choose your right plan!",
      selectAgent: "Select an agent.",
      selectBestPlans:
        "Select from the best plans, ensuring a perfect match. Need more or less? Customize your subscription for a seamless fit!",
      primaryPackages: "Primary Packages",
      addonPackages: "Addon Packages",
      buyAddonAnytime:
        "You can buy an addon package anytime you need extra features!",
      alreadySubscribed: "Already Subscribed",
      subscribeNow: "Subscribe Now",
      buyAddon: "Buy Addon",
      backToHomepage: "Go Back to Homepage",
      noPricingDetailsAvailable: "No pricing details available.",
      noAddonPackagesAvailable: "No addon packages available.",
      noFeaturesListed: "No features listed.",
      errorFetchingPricingDetails: "Error fetching pricing details.",
      failedToFetchPricingDetails:
        "Failed to fetch pricing details. Please try again.",
      subscriptionError: "Error occurred during subscription.",
      subscriptionFailed: "Subscription failed. Please try again.",
      cancelSubscription: "Cancel Subscription",
      subscriptionCancelled: "Subscription successfully canceled.",
      cancelSubscriptionFailed:
        "Failed to cancel subscription. Please try again",
      integrations: "Integrations",
      twilio: "Twilio",
      integrateTwilio: "Integrate Twilio",
      resend: "Resend",
      integrateResend: "Integrate Resend",
      calendly: "Calendly",
      integrateCalendly: "Integrate Calendly",
      crm: "CRM",
      integrateCRM: "Integrate CRM",
      disconnectCRM: "Disconnect CRM",
      integrateTwilioTitle: "Integrate Twilio",
      twilioAccountSID: "Account SID",
      twilioAuthToken: "Auth Token",
      integrateResendTitle: "Integrate Resend",
      resendAPIKey: "Resend API Key",
      fromEmail: "From Email",
      integrateCalendlyTitle: "Integrate Calendly",
      calendlyURL: "Calendly URL",
      selectTimeZone: "Select Time Zone",
      integrateCRMTitle: "Integrate CRM",
      crmWebhookURL: "Webhook URL",
      numberManagement: "Number Management",
      scriptManagement: "Script Management",
      agentManagement: "Agent Management",
      manageAgents: "Manage Agents",
      createAgent: "Create Agent",
      delete: "Delete",
      deleteAgentConfirmation: "Are you sure you want to delete this agent?",
      deleteAgentTooltip: "Click to delete this agent.",
      yes: "Yes",
      no: "No",
      failedToDeleteAgent: "Failed to delete agent.",
      agentDeleted: "Agent deleted successfully.",
      actions: "Actions",
      agentName: "Agent Name",
      crmTooltip:
        "At the end of each call, a POST request with all details will be sent to this URL.",
      // SMS From Number
      smsFromNumber: "SMS From Number",
      setSmsFromNumber: "Set SMS From Number",
      setSmsFromNumberTitle: "Set SMS From Number",

      // Company Email
      companyEmail: "Company Email",
      setCompanyEmail: "Set Company Email",
      setCompanyEmailTitle: "Set Company Email",

      // Additional Labels and Messages
      twilioIntegratedSuccess: "Twilio integrated successfully",
      twilioIntegratedFailure: "Failed to integrate Twilio",
      smsFromNumberSetSuccess: "SMS 'From Number' set successfully",
      smsFromNumberSetFailure: "Failed to set SMS 'From Number'",
      companyEmailSetSuccess: "Company Email set successfully",
      companyEmailSetFailure: "Failed to set Company Email",
      resendIntegratedSuccess: "Resend integrated successfully",
      resendIntegratedFailure: "Failed to integrate Resend",
      calendlyIntegratedSuccess: "Calendly integrated successfully",
      calendlyIntegratedFailure: "Failed to integrate Calendly",
      crmIntegratedSuccess: "CRM integrated successfully",
      crmIntegratedFailure: "Failed to integrate CRM",
      crmDisconnectedSuccess: "CRM disconnected successfully",
      crmDisconnectedFailure: "Failed to disconnect CRM",
      invalidEmail: "Please enter a valid email address.",
      requiredField: "This field is required.",
      timeZone: "Time Zone",
      fromNumber: "From Number",
      calendarIntegratedSuccess: "Calendar integrated successfully",
      calendarIntegratedFailure: "Failed to integrate Calendar",
      integrateCalendar: "Integrate Calendar",
      integrateCalendarTitle: "Calendar Integration",
      checkSlotsWebhook: "Check Free Slots Webhook",
      bookSlotWebhook: "Book Slot Webhook",
      selectTimeZone: "Select Time Zone",
      calendarIntegration: "Calendar Integration",
    },
  },
  de: {
    translation: {
      // Existing keys
      selectAgent: "Wählen Sie einen Agenten aus.",
      chooseYourRightPlan: "Wählen Sie Ihren richtigen Plan!",
      smsMessagePlaceholder: "SMS-Nachricht eingeben.",
      physicalPerson: "Kontaktdaten natürlicher Personen",
      physical: "natürliche Personen",
      productDetails: "Produktdetails",
      welcome: "Willkommen auf Ihrem Dashboard",
      welcomeMessage:
        "Willkommen im Kampagnen-Management-Dashboard. Hier können Sie mühelos Ihre Kampagnen verwalten, CSV-Dateien hochladen, um neue Kampagnen zu starten, und Ihre Analysen überwachen, um datengesteuerte Entscheidungen zu treffen. Lassen Sie uns beginnen!",
      uploadFile: "Hochladen",
      addScript: "Skript hinzufügen",
      testScript: "Skript testen",
      addScriptDetails: "Skriptdetails hinzufügen",
      scriptDetails: "Skriptdetails",
      editScript: "Skript bearbeiten",
      selectScript: "Skript auswählen",
      deleteScript: "Skript löschen",
      aiUpgrade: "KI-Upgrade",
      showScript: "Skript anzeigen",
      manageScripts: "Skripte verwalten",
      startCampaign: "Kampagne starten",
      start: "Starten",
      campaignHistory: "Kampagnenverlauf",
      getAllBatches: "Alle Chargen abrufen",
      batchDetail: "Chargen details",
      upgradeToPro: "Auf Pro upgraden",
      integrations: "Integrationen",
      userProfile: "Benutzerprofil",
      purchaseNumber: "Nummer kaufen",
      logout: "Abmelden",
      campaignId: "Kampagnen-ID",
      createdAt: "Erstellt am",
      totalCall: "Gesamtanzahl der Anrufe",
      completedCall: "Abgeschlossene Anrufe",
      inProgressCall: "Laufende Anrufe",
      callDetails: "Anrufdetails",
      noCallDetails: "Keine Details verfügbar",
      status: "Status",
      details: "Einzelheiten",
      analyze: "Analyse generieren",
      stopCampaign: "Kampagne stoppen",
      enterCampaignId: "Kampagnen-ID eingeben",
      enterGreetings: "Begrüßung eingeben",
      firstSentence: "Geben Sie den ersten Satz des Anrufskripts ein",
      selectLanguage: "Sprache auswählen",
      selectVoice: "Stimme auswählen",
      selectVoiceTitle: "Wählen Sie eine Stimme für den KI-Agenten.",
      scheduleType: "Planungstyp auswählen",
      startDate: "Startdatum auswählen",
      startTime: "Startzeit auswählen",
      selectTimeZone: "Zeitzone auswählen",
      callsPerHour: "Anrufe pro Stunde eingeben",
      cancel: "Abbrechen",
      save: "Speichern",
      useDefault: "Standard verwenden",
      inboundCallManagement: "Eingehendes Anrufmanagement",
      inbound: "Eingehend",
      assignNumbers: "Nummern zuweisen",
      assignNumbersToAgent: "Nummern einem Agenten zuweisen",
      setAgent: "Agent festlegen",
      sms: "SMS",
      smsLabel: "SMS nach dem Anruf senden",
      email: "E-Mail",
      emailLabel: "E-Mail nach dem Anruf senden",
      emailMessagePlaceholder: "E-Mail-Nachricht eingeben",
      integrateTwilio: "Twilio integrieren",
      integrateResend: "Resend integrieren",
      integrate: "Integrieren",
      integrateCalendly: "Calendly integrieren",
      saveScript: "Skript speichern",
      scriptName: "Skriptname",
      enterScriptName: "Skriptname eingeben",
      greetingSentence: "Begrüßungssatz",
      enterGreetingSentence: "Begrüßungssatz eingeben",
      companyDetails: "Firmendetails",
      address: "Adresse",
      homepage: "Startseite",
      phoneNumber: "Telefonnummer",
      clientObjections: "Kundeneinwände",
      modifyScript: "Skript ändern",
      selectScriptTooltip:
        "Wählen Sie das Skript aus, das für diese Kampagne verwendet werden soll.",
      firstSentenceTooltip:
        "Der erste Satz, der von der Stimme gesprochen wird.",
      outboundNumberTooltip: "Wählen Sie die ausgehende Nummer für die Anrufe.",
      callSchedulingTooltip: "Wählen Sie, wann der Anruf erfolgen soll.",
      callSchedulingTitle:
        "Planen Sie, wann der Anruf stattfinden soll. Anrufe können sofort erfolgen oder für einen späteren Zeitpunkt geplant werden.",
      selectDateTooltip: "Wählen Sie das Datum für den geplanten Anruf.",
      selectTimeTooltip: "Wählen Sie die Uhrzeit für den geplanten Anruf.",
      selectTimeZoneTooltip: "Wählen Sie die Zeitzone für den geplanten Anruf.",
      uploadFileTooltip:
        "Laden Sie eine CSV-Datei hoch, die die Daten enthält, um Ihre Kampagne zu starten.",
      campaignIdTooltip:
        "Eindeutiger Bezeichner für Ihre Kampagne. Stellen Sie sicher, dass diese ID eindeutig ist.",
      voiceTooltip:
        "Wählen Sie eine Stimme für den KI-Agenten. Standardoptionen sind: Maya, Mason, Ryan, Adriana, Tina, Matt, Evelyn.",
      pronunciationGuideTooltip:
        "Geben Sie spezifische Aussprachen für bestimmte Wörter oder Sätze an.",
      transferListTooltip:
        "Liste der Abteilungen und ihrer entsprechenden Telefonnummern für Anrufweiterleitungen.",
      retryConfigurationTooltip:
        "Konfigurieren Sie die Wiederholungsoptionen für Anrufe, die auf die Mailbox gehen.",
      smsTooltip: "Aktivieren Sie den Versand von SMS nach dem Anruf.",
      emailTooltip: "Aktivieren Sie den Versand einer E-Mail nach dem Anruf.",
      calendlyTooltip:
        "Aktivieren Sie die Calendly-Integration, um Termine direkt aus dem Anruf zu buchen.",
      pronunciationWord: "Wort",
      pronunciationWordTooltip:
        "Das Wort, das eine Ausspracheanleitung benötigt.",
      pronunciation: "Aussprache",
      pronunciationTooltip: "Wie das Wort ausgesprochen werden soll.",
      caseSensitive: "Groß- und Kleinschreibung beachten",
      caseSensitiveTooltip:
        "Soll das Wort groß- und kleinschreibungssensitiv behandelt werden?",
      spaced: "Gesondert",
      spacedTooltip: "Soll die Aussprache auseinandergezogen werden?",
      remove: "Entfernen",
      addPronunciation: "Aussprache hinzufügen",
      department: "Abteilung",
      addTransfer: "Weiterleitung hinzufügen",
      waitTime: "Wartezeit (Sekunden)",
      analytics: "Analytik",
      waitTimeTooltip:
        "Die Verzögerung, bevor der Anruf im Falle einer Mailbox erneut versucht wird.",
      voicemailAction: "Mailbox-Aktion",
      voicemailActionTooltip:
        "Aktion, die durchgeführt wird, wenn der Anruf auf die Mailbox trifft.",
      voicemailMessage: "Mailbox-Nachricht",
      voicemailMessageTooltip:
        "Die Nachricht, die hinterlassen wird, wenn die Mailbox erreicht wird.",
      callImmediately: "Sofort anrufen",
      scheduleCall: "Anruf planen",
      enableNotesEmailing: "Notizen per E-Mail senden aktivieren",
      enableNotesEmailingTooltip:
        "Aktivieren Sie die Funktion, dass der KI-Agent Notizen nach dem Anruf an die Firmen-E-Mail sendet.",
      enterCompanyEmail: "Firmen-E-Mail eingeben",
      webhookIntegration: "Webhook-Integration",
      webhookIntegrationTooltip:
        "Integration mit einem Webhook zur Echtzeit-Datenübertragung aktivieren.",
      aiUpgradingTooltip:
        "Verwenden Sie KI, um den Inhalt des Skripts automatisch zu verbessern.",
      // Added keys
      selectCategory: "Kategorie auswählen",
      selectCategoryTitle: "Wählen Sie zwischen normalen und Premiumstimmen.",
      normalVoices: "Normale Stimmen",
      premiumVoices: "Premium-Stimmen",
      pronunciationGuide: "Ausspracheführer",
      transferList: "Übertragungsliste",
      retryConfiguration: "Wiederholungsoptionen",
      postCallActions: "Aktionen nach dem Anruf",
      language: "Sprache",
      home: "Startseite",
      failedToFetchNumbers: "Fehler beim Abrufen der Telefonnummern.",
      analysisDataFetched: "Analysedaten erfolgreich abgerufen!",
      failedToFetchAnalysisData: "Fehler beim Abrufen der Analysedaten.",
      callDetailsFetched: "Anrufdetails erfolgreich abgerufen!",
      failedToFetchCallDetails: "Fehler beim Abrufen der Anrufdetails.",
      totalPrice: "Gesamtpreis",
      topCaller: "Top-Anrufer",
      noData: "Keine Daten",
      count: "Anzahl",
      totalDuration: "Gesamtdauer",
      seconds: "Sekunden",
      averageDuration: "Durchschnittliche Dauer",
      distributionByTime: "Verteilung nach Zeit",
      morning: "Morgen",
      afternoon: "Nachmittag",
      evening: "Abend",
      night: "Nacht",
      callEndedBy: "Anruf beendet von",
      assistant: "Assistent",
      user: "Benutzer",
      callId: "Anruf-ID",
      from: "Von",
      to: "An",
      duration: "Dauer",
      completed: "Abgeschlossen",
      yes: "Ja",
      no: "Nein",
      endAt: "Beendet am",
      price: "Preis",
      summary: "Zusammenfassung",
      open: "Öffnen",
      callSummary: "Anrufzusammenfassung",
      createAgent: "Agent erstellen",
      saveAgent: "Agent speichern",
      enterAgentName: "Agentennamen eingeben",
      integrateSmsService:
        "Bitte integrieren Sie den SMS-Dienst, bevor Sie ihn aktivieren.",
      enterSmsMessage: "SMS-Nachricht eingeben",
      enableEmailTooltip:
        "Aktivieren Sie den Versand einer E-Mail nach dem Anruf.",
      integrateEmailService:
        "Bitte integrieren Sie den E-Mail-Dienst, bevor Sie ihn aktivieren.",
      enterEmailMessage: "E-Mail-Nachricht eingeben",
      companyEmailTooltip: "Geben Sie die Firmen-E-Mail für die KI-E-Mail ein.",
      enableCalendlyTooltip: "Terminvereinbarung über Calendly aktivieren.",
      integrateCalendlyService:
        "Bitte integrieren Sie Calendly, bevor Sie es aktivieren.",
      aiUpgradeScript: "Skript mit KI verbessern",
      selectNumberFirst: "Bitte wählen Sie zuerst eine Telefonnummer aus.",
      selectNumber: "Wählen Sie eine Nummer",
      failedToFetchAgents: "Fehler beim Abrufen der Agenten.",
      integrateSmsService:
        "Bitte integrieren Sie den SMS-Dienst, bevor Sie ihn aktivieren.",
      integrateEmailService:
        "Bitte integrieren Sie den E-Mail-Dienst, bevor Sie ihn aktivieren.",
      failedToFetchScripts: "Fehler beim Abrufen der Skripte.",
      failedToFetchVoices: "Fehler beim Abrufen der Stimmen.",
      numbersFetched: "Telefonnummern erfolgreich abgerufen!",
      selectPhoneNumbers: "Telefonnummern auswählen",
      aiUpgrade: "KI-Upgrade",
      loggedOut: "Sie wurden abgemeldet.",
      contactSupport: "Support kontaktieren",
      selectOutboundNumber: "Ausgehende Nummer auswählen",
      selectDate: "Datum auswählen",
      selectTime: "Uhrzeit auswählen",
      selectTimeZone: "Zeitzone auswählen",
      fileUploadedSuccessfully: "Datei erfolgreich hochgeladen",
      errorProcessingFile: "Fehler beim Verarbeiten der Datei",
      errorUploadingFile: "Fehler beim Hochladen der Datei",
      failedToFetchPrompts: "Fehler beim Abrufen der Prompts.",
      failedToFetchIntegrations:
        "Fehler beim Abrufen der Benutzerintegrationen.",
      agentManagement: "Agentenverwaltung",
      campaign: "Kampagne",
      user: "Benutzer",
      help: "Hilfe",
      phoneNumberManagement: "Telefonnummernverwaltung",
      contactSupport: "Support kontaktieren",
      manageAgent: "Agenten verwalten",
      language: "Sprache",
      upgradeToPro: "Auf Pro upgraden",
      addAgent: "Agent hinzufügen",
      home: "Startseite",
      Tutorials: "Anleitungen",
      Help: "helfen",
      description: "Beschreibung",
      client: "Kunde",
      sellingMethod: "Verkaufsmethode",
      usps: "USPs",
      advantages: "Vorteile",
      specialFeatures: "Besondere Merkmale",
      aiAgent: "KI-Agent",
      character: "Charakter",
      talkingSpeed: "Sprechgeschwindigkeit",
      tone: "Ton",
      voiceSound: "Stimmklang",
      importantEmotions: "Wichtige Emotionen",
      nofilechosen: "Keine Datei ausgewählt",
      Choosefile: "Datei auswählen",
      firstSentence: "Erster Satz",
      enterFirstSentence: "Ersten Satz eingeben",
      selectOutboundNumber: "Ausgehende Nummer auswählen",
      callImmediately: "Sofort anrufen",
      scheduleCall: "Anruf planen",
      callScheduling: "Anrufplanung",
      pronunciationGuide: "Ausspracheführer",
      transferList: "Übertragungsliste",
      addTransfer: "Weiterleitung hinzufügen",
      retryConfiguration: "Wiederholungsoptionen",
      waitTimeMinutes: "Wartezeit (Minuten)",
      enableAppointmentSetting: "Terminplanung aktivieren",
      enableNotesEmailing: "Notizen per E-Mail senden aktivieren",
      name: "Name",
      subscriptionStatus: "Abonnementstatus",
      callLimit: "Anruflimit",
      generateAndDownloadInvoice: "Rechnung generieren und herunterladen",
      cancelSubscription: "Abonnement kündigen",
      deleteAccount: "Konto löschen",
      backToHomepage: "Zur Startseite zurückkehren",
      back: "zurück",
      integrateCRM: "CRM integrieren",
      disconnectCRM: "CRM trennen",
      frequentlyAskedQuestions: "Häufig gestellte Fragen",
      twilioNumbers: "Twilio-Nummern",
      newTwilioNumber: "Neue Twilio-Nummer",
      connectNumber: "Nummer verbinden",
      leads: "Kontakte",
      backToOutbound: "Zurück zu ausgehend",
      alreadySubscribed: "Bereits abonniert",
      month: "Monat",
      selectAction: "Aktion auswählen",
      ignore: "Ignorieren",
      leaveMessage: "Nachricht hinterlassen",
      hangup: "Auflegen",
      enterWaitTimeMinutes: "Wartezeit in Minuten eingeben",
      smsMessagePlaceholder: "SMS-Nachricht eingeben.",
      chooseYourRightPlan: "Wählen Sie den richtigen Plan!",
      selectBestPlans:
        "Wählen Sie aus den individuellen Paketen aus. Benötigen Sie mehr Calls für diesen Monat? Passen Sie Ihr Anruflimit an um maximale Effizienz zu erreichen!",
      primaryPackages: "Basispaket",
      addonPackages: "Zusatzpakete",
      buyAddonAnytime:
        "Sie können jederzeit ein Addon-Paket kaufen, wenn Sie zusätzliche Funktionen benötigen!",
      alreadySubscribed: "Bereits abonniert",
      subscribeNow: "Jetzt abonnieren",
      buyAddon: "Addon kaufen",
      backToHomepage: "Zurück zur Startseite",
      noPricingDetailsAvailable: "Keine Preisinformationen verfügbar.",
      noAddonPackagesAvailable: "Keine Addon-Pakete verfügbar.",
      noFeaturesListed: "Keine Funktionen aufgeführt.",
      errorFetchingPricingDetails:
        "Fehler beim Abrufen der Preisinformationen.",
      failedToFetchPricingDetails:
        "Preisinformationen konnten nicht abgerufen werden. Bitte versuchen Sie es erneut.",
      subscriptionError: "Fehler bei der Anmeldung.",
      subscriptionFailed:
        "Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.",
      cancelSubscription: "Abonnement kündigen",
      subscriptionCancelled: "Abonnement erfolgreich gekündigt.",
      cancelSubscriptionFailed:
        "Fehler beim Kündigen des Abonnements. Bitte versuchen Sie es erneut",
      integrations: "Integrationen",
      twilio: "Twilio",
      integrateTwilio: "Twilio integrieren",
      resend: "Resend",
      integrateResend: "Resend integrieren",
      calendly: "Calendly",
      integrateCalendly: "Calendly integrieren",
      crm: "CRM",
      integrateCRM: "CRM integrieren",
      disconnectCRM: "CRM trennen",
      integrateTwilioTitle: "Twilio integrieren",
      twilioAccountSID: "Account SID",
      twilioAuthToken: "Auth Token",
      integrateResendTitle: "Resend integrieren",
      resendAPIKey: "Resend API-Schlüssel",
      fromEmail: "Von E-Mail",
      integrateCalendlyTitle: "Calendly integrieren",
      calendlyURL: "Calendly URL",
      selectTimeZone: "Zeitzone auswählen",
      integrateCRMTitle: "CRM integrieren",
      crmWebhookURL: "Webhook-URL",
      numberManagement: "Nummernverwaltung",
      scriptManagement: "Skriptverwaltung",
      analysis: "Analyse",
      analyze: "Analysieren",
      selectNumberFirst: "Bitte wählen Sie zuerst eine Nummer aus",
      agentManagement: "Agentenverwaltung",
      manageAgents: "Agenten verwalten",
      createAgent: "Agent erstellen",
      delete: "Löschen",
      deleteAgentConfirmation:
        "Sind Sie sicher, dass Sie diesen Agenten löschen möchten?",
      deleteAgentTooltip: "Klicken Sie, um diesen Agenten zu löschen.",
      yes: "Ja",
      no: "Nein",
      failedToDeleteAgent: "Agent konnte nicht gelöscht werden.",
      agentDeleted: "Agent erfolgreich gelöscht.",
      actions: "Aktionen",
      agentName: "Agentenname",
      crmTooltip:
        "Am Ende jedes Anrufs wird eine POST-Anfrage mit allen Details an diese URL gesendet.",
      // SMS From Number
      smsFromNumber: "SMS Absendernummer",
      setSmsFromNumber: "SMS Absendernummer festlegen",
      setSmsFromNumberTitle: "SMS Absendernummer festlegen",

      // Company Email
      companyEmail: "Firmen-E-Mail",
      setCompanyEmail: "Firmen-E-Mail festlegen",
      setCompanyEmailTitle: "Firmen-E-Mail festlegen",

      // Additional Labels and Messages
      twilioIntegratedSuccess: "Twilio erfolgreich integriert",
      twilioIntegratedFailure: "Twilio-Integration fehlgeschlagen",
      smsFromNumberSetSuccess: "SMS 'Absendernummer' erfolgreich festgelegt",
      smsFromNumberSetFailure:
        "Festlegen der SMS 'Absendernummer' fehlgeschlagen",
      companyEmailSetSuccess: "Firmen-E-Mail erfolgreich festgelegt",
      companyEmailSetFailure: "Festlegen der Firmen-E-Mail fehlgeschlagen",
      resendIntegratedSuccess: "Resend erfolgreich integriert",
      resendIntegratedFailure: "Resend-Integration fehlgeschlagen",
      calendlyIntegratedSuccess: "Calendly erfolgreich integriert",
      calendlyIntegratedFailure: "Calendly-Integration fehlgeschlagen",
      crmIntegratedSuccess: "CRM erfolgreich integriert",
      crmIntegratedFailure: "CRM-Integration fehlgeschlagen",
      crmDisconnectedSuccess: "CRM erfolgreich getrennt",
      crmDisconnectedFailure: "Trennen des CRM fehlgeschlagen",
      invalidEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
      requiredField: "Dieses Feld ist erforderlich.",
      timeZone: "Zeitzone",
      fromNumber: "Absendernummer",
      calendarIntegratedSuccess: "Kalender erfolgreich integriert",
      calendarIntegratedFailure: "Kalender-Integration fehlgeschlagen",
      integrateCalendar: "Kalender integrieren",
      integrateCalendarTitle: "Kalender-Integration",
      checkSlotsWebhook: "Webhook für verfügbare Slots prüfen",
      bookSlotWebhook: "Webhook für Slot-Buchung",
      selectTimeZone: "Zeitzone auswählen",
      calendarIntegration: "Kalender-Integration",
    },
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
