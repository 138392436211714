import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Select, message, Form } from "antd";
import axios from "axios";
import { BlandWebClient } from "bland-client-js-sdk";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const path = process.env.REACT_APP_API_PATH;

const ScriptManagement = ({ userId, showScriptModal, setShowScriptModal }) => {
  const { t } = useTranslation();
  const [modalType, setModalType] = useState("see"); // 'see', 'add', 'edit', or 'show'
  const [availableScripts, setAvailableScripts] = useState([]);
  const [selectedScriptId, setSelectedScriptId] = useState(null);
  const [scriptName, setScriptName] = useState("");
  const [greeting, setGreeting] = useState("");
  const [agentId, setAgentId] = useState(null);

  const [companyDetails, setCompanyDetails] = useState({
    address: "",
    homepage: "",
    email: "",
    phoneNumber: "",
    contactPerson: "",
  });
  const [productDetails, setProductDetails] = useState({
    description: "",
    client: "",
    sellingMethod: "",
    usps: "",
    advantages: "",
    specialFeatures: "",
  });
  const [aiAgentDetails, setAiAgentDetails] = useState({
    character: "",
    talkingSpeed: "",
    tone: "",
    voiceSound: "",
    importantEmotions: "",
  });
  const [clientObjections, setClientObjections] = useState("");
  const [structuredContent, setStructuredContent] = useState("");
  const [isShowScriptModalVisible, setIsShowScriptModalVisible] =
    useState(false);
  // const [availableVoices, setAvailableVoices] = useState([]);
  // const [selectedVoice, setSelectedVoice] = useState("");
  const [normalVoices, setNormalVoices] = useState([]);
  const [premiumVoices, setPremiumVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState("");
  const [voiceCategory, setVoiceCategory] = useState("normal"); // Default to 'normal'
  const [isConversationRunning, setIsConversationRunning] = useState(false);
  const [blandClient, setBlandClient] = useState(null);

  const { Option } = Select;

  useEffect(() => {
    if (modalType === "see") {
      fetchScripts();
    }
  }, [modalType]);

  const fetchScripts = async () => {
    try {
      const response = await axios.get(`${path}/user_scripts_inbound`, {
        params: { user_id: userId },
      });
      setAvailableScripts(response.data);
      message.success("Scripts fetched successfully!");
    } catch (error) {
      console.error("Error fetching scripts:", error);
      message.error("Failed to fetch scripts.");
    }
  };

  const fetchNormalVoices = async () => {
    try {
      const response = await axios.get(`${path}/voices`);
      const voices = response.data.voices || [];

      const germanVoices = voices.filter((voice) => {
        const description = voice.description || "";
        const tags = voice.tags || [];

        const isGermanTag = tags.some((tag) =>
          tag.toLowerCase().includes("german")
        );
        const isGermanDescription = description
          .toLowerCase()
          .includes("german");

        const commonGermanWords = [
          "und",
          "der",
          "die",
          "das",
          "ist",
          "nicht",
          "ein",
          "eine",
          "mit",
          "auf",
          "für",
          "von",
          "zu",
          "haben",
        ];
        const isDescriptionInGerman = commonGermanWords.some((word) =>
          description.toLowerCase().includes(word)
        );

        return isGermanTag || isGermanDescription || isDescriptionInGerman;
      });

      setNormalVoices(germanVoices);
      message.success("German voices fetched successfully!");
    } catch (error) {
      message.error("Failed to fetch voices: " + error.message);
    }
  };
  const fetchPremiumVoices = async () => {
    try {
      const response = await axios.get(`${path}/admin/voices`);
      console.log("Premium Voices Response: ", response.data); // Debug line
      const voices = response.data || [];
      setPremiumVoices(voices);
    } catch (error) {
      console.error("Failed to fetch premium voices: ", error); // Debug line
      message.error("Failed to fetch premium voices: " + error.message);
    }
  };

  useEffect(() => {
    if (voiceCategory === "normal") {
      fetchNormalVoices();
    } else if (voiceCategory === "premium") {
      fetchPremiumVoices();
    }
  }, [voiceCategory]);

  const handleSaveScript = async () => {
    if (!scriptName) {
      message.error("Please provide a script name.");
      return;
    }

    const generatedScript = {
      name: scriptName,
      greeting: greeting,
      user_id: userId,
      companyDetails: companyDetails,
      productDetails: productDetails,
      aiAgentDetails: aiAgentDetails,
      clientObjections: clientObjections,
      structured_content: structuredContent,
    };

    try {
      const hide = message.loading({
        content: "Saving data, generating your script...",
        duration: 0,
      });

      if (modalType === "add") {
        await axios.post(`${path}/user_script_inbound`, generatedScript);
        message.success("Script added successfully!");
      } else if (modalType === "edit") {
        await axios.put(`${path}/user_script_inbound`, {
          ...generatedScript,
          script_id: selectedScriptId,
        });
        message.success("Script updated successfully!");
      }

      hide();
      fetchScripts();
      setShowScriptModal(false);
    } catch (error) {
      message.error("Failed to save script: " + error.message);
    }
  };

  const handleSaveStructuredScript = async () => {
    if (!selectedScriptId || !structuredContent) {
      message.error("Script ID and structured content are required.");
      return;
    }

    try {
      const hide = message.loading({
        content: "Saving script content...",
        duration: 0,
      });

      await axios.put(`${path}/update-script-inbound`, {
        script_id: selectedScriptId,
        structured_content: structuredContent,
      });

      message.success("Structured script content updated successfully!");
      setIsShowScriptModalVisible(false);
      hide();
      fetchScripts();
    } catch (error) {
      message.error("Failed to update script content: " + error.message);
    }
  };

  const handleDeleteScript = async () => {
    try {
      await axios.delete(`${path}/user_script_inbound`, {
        data: { script_id: selectedScriptId },
      });
      message.success("Script deleted successfully!");
      fetchScripts();
    } catch (error) {
      message.error("Failed to delete script: " + error.message);
    }
  };

  const handleAIUpgrade = async () => {
    if (!selectedScriptId) {
      message.error("No script selected.");
      return;
    }

    const hide = message.loading({
      content: "AI is generating your script...",
      duration: 0,
    });

    const upgradeData = {
      script_id: selectedScriptId,
      user_id: userId,
      greeting: greeting,
      companyDetails: companyDetails,
      productDetails: productDetails,
      aiAgentDetails: aiAgentDetails,
      clientObjections: clientObjections,
    };

    try {
      const response = await axios.post(
        `${path}/ai_upgrade_script_inbound`,
        upgradeData
      );
      const upgradedContent = response.data.content;

      setStructuredContent(upgradedContent);
      message.success("Script upgraded successfully!");
    } catch (error) {
      message.error("Failed to upgrade script: " + error.message);
    } finally {
      hide();
    }
  };

  const handleShowScript = () => {
    if (!structuredContent) {
      message.error("No structured content available.");
      return;
    }

    setIsShowScriptModalVisible(true);
  };

  const handleTestScript = async () => {
    if (!selectedVoice || !selectedScriptId) {
      message.warning(
        "Please select a voice and script before testing the script."
      );
      return;
    }

    try {
      // Step 1: Create a new agent
      const agentId = await createAgent(selectedScriptId, selectedVoice);
      if (!agentId) {
        throw new Error("Agent creation failed. Agent ID is undefined.");
      }
      setAgentId(agentId);

      // Step 2: Authorize the new agent
      const sessionToken = await authorizeAgent(agentId);
      if (!sessionToken) {
        throw new Error("Authorization failed. Session token is undefined.");
      }

      // Step 3: Initialize the conversation
      const client = new BlandWebClient(agentId, sessionToken);

      await client.initConversation({
        sampleRate: 44100,
      });

      setIsConversationRunning(true);
      setBlandClient(client);

      setTimeout(() => {
        message.success("Conversation initialized successfully!");
      }, 5000);
    } catch (error) {
      message.error("Failed to update agent for test.");
    }
  };

  // Function to create a new agent
  const createAgent = async (scriptId, voice) => {
    try {
      const response = await axios.post(`${path}/create-agent`, {
        script_id: scriptId,
        voice: voice,
        language: "GER",
        model: "enhanced",
      });

      if (response && response.data && response.data.agent_id) {
        console.log(
          "Agent created successfully with ID:",
          response.data.agent_id
        );
        return response.data.agent_id;
      } else {
        console.error("Failed to retrieve agent ID from response:", response);
        throw new Error("Invalid response from agent creation API.");
      }
    } catch (error) {
      console.error("Error creating agent:", error);
      throw new Error("Agent creation failed.");
    }
  };

  // Function to authorize the agent
  const authorizeAgent = async (agentId) => {
    try {
      const response = await axios.post(`${path}/authorize-agent`, {
        agentId: agentId,
      });

      if (response && response.data && response.data.token) {
        console.log(
          "Agent authorized successfully. Session token:",
          response.data.token
        );
        return response.data.token;
      } else {
        console.error(
          "Failed to retrieve session token from response:",
          response
        );
        throw new Error("Invalid response from agent authorization API.");
      }
    } catch (error) {
      console.error("Error authorizing agent:", error);
      throw new Error("Agent authorization failed.");
    }
  };

  // Function to initialize the conversation
  const initializeConversation = async (agentId, sessionToken) => {
    try {
      const client = new BlandWebClient(agentId, sessionToken);
      await client.initConversation({ sampleRate: 44100 });
      console.log("Conversation initialized successfully for agent:", agentId);
      return client;
    } catch (error) {
      console.error("Error initializing conversation:", error);
      throw new Error("Conversation initialization failed.");
    }
  };

  const handleStopConversation = async () => {
    try {
      if (blandClient) {
        await blandClient.stopConversation(); // Stop the conversation
        message.success("Conversation stopped successfully.");

        // Delete the agent
        await axios.post(`${path}/delete-agent`, {
          agentId: agentId, // Use the agentId saved during start
        });

        // Reset the UI state
        setIsConversationRunning(false);
        setBlandClient(null);
        setAgentId(null);
      }
    } catch (error) {
      console.error("Error stopping the conversation:", error);
      message.error("Failed to stop the conversation.");
    }
  };
  const handleScriptSelection = (scriptId) => {
    const script = availableScripts.find((s) => s._id === scriptId);
    if (script) {
      setSelectedScriptId(script._id);
      setScriptName(script.name);
      setGreeting(script.greeting);
      setCompanyDetails(script.company_details);
      setProductDetails(script.product_details);
      setAiAgentDetails(script.ai_agent_details);
      setClientObjections(script.client_objections);
      setStructuredContent(script.structured_content);
    }
  };

  return (
    // <Modal
    //   title={modalType === "edit" ? "Edit Script" : "Manage Scripts"}
    //   visible={showScriptModal}
    //   onCancel={() => setShowScriptModal(false)}
    //   footer={null}
    //   className="p-3"
    // >
    <div>
      <h1>{t("manageAgentss")}</h1>
      {modalType === "see" && (
        <div>
          <Select
            placeholder={t("selectScript")}
            style={{ width: "100%", marginBottom: 10 }}
            onChange={handleScriptSelection}
            value={selectedScriptId}
          >
            {availableScripts.map((script) => (
              <Select.Option key={script._id} value={script._id}>
                {script.name}
              </Select.Option>
            ))}
          </Select>
          <Button
            className="btn btn-primary mb-2"
            onClick={() => setModalType("edit")}
            disabled={!selectedScriptId}
          >
            {t("editScript")}
          </Button>
          <Button
            className="btn btn-danger mb-2"
            onClick={handleDeleteScript}
            disabled={!selectedScriptId}
          >
            {t("deleteScript")}
          </Button>
          <Button
            className="btn btn-warning mb-2"
            onClick={handleAIUpgrade}
            disabled={!selectedScriptId}
          >
            {t("aiUpgrade")}
          </Button>
          <Button
            className="btn btn-info mb-2"
            onClick={handleShowScript}
            disabled={!structuredContent}
          >
            {t("showScript")}
          </Button>
          {/* <Select
            placeholder={t("selectVoice")}
            style={{ width: "100%", marginBottom: 10 }}
            onChange={setSelectedVoice}
            disabled={isConversationRunning}
          >
            {availableVoices.length > 0 ? (
              availableVoices.map((voice) => (
                <Select.Option key={voice.id} value={voice.id}>
                  {voice.name} - {voice.description}
                </Select.Option>
              ))
            ) : (
              <Select.Option disabled>No German voices available</Select.Option>
            )}
          </Select> */}

          <Select
            placeholder={t("selectCategoryTitle")}
            style={{ width: "100%", marginBottom: 10 }}
            onChange={(value) => setVoiceCategory(value)}
            value={voiceCategory}
          >
            <Select.Option value="normal">{t("normalVoices")}</Select.Option>
            <Select.Option value="premium">{t("premiumVoices")}</Select.Option>
          </Select>

          <Select
            placeholder={t("Select Voice")}
            style={{ width: "100%", marginBottom: 10 }}
            onChange={setSelectedVoice}
            value={selectedVoice}
            disabled={isConversationRunning}
          >
            {(voiceCategory === "normal" ? normalVoices : premiumVoices).map(
              (voice) => (
                <Select.Option
                  key={voice._id || voice.id}
                  value={voice._id || voice.id}
                >
                  {voice.name} - {voice.description}
                </Select.Option>
              )
            )}
          </Select>

          <Button
            className="btn btn-success mb-2"
            onClick={handleTestScript}
            disabled={!selectedScriptId || isConversationRunning}
          >
            {t("testScript")}
          </Button>
          {isConversationRunning && (
            <Button
              className="btn btn-danger"
              onClick={handleStopConversation}
              style={{ marginTop: 10 }}
            >
              Stop Test
            </Button>
          )}
          {/* <Button
            className="btn btn-secondary mt-3"
            block
            onClick={() => setModalType("see")}
          >
            Back
          </Button> */}
        </div>
      )}

      {(modalType === "add" || modalType === "edit") && (
        <Form layout="vertical">
          <h3 className="mb-3">Script Details</h3>
          <Form.Item label="Script Name">
            <Input
              value={scriptName}
              onChange={(e) => setScriptName(e.target.value)}
              placeholder="Enter script name"
            />
          </Form.Item>
          <Form.Item label="Greeting Sentence">
            <Input
              value={greeting}
              onChange={(e) => setGreeting(e.target.value)}
              placeholder="Enter greeting sentence"
            />
          </Form.Item>
          <h3 className="mb-3">Company Details</h3>
          <Form.Item label="Address">
            <Input
              value={companyDetails.address}
              onChange={(e) =>
                setCompanyDetails({
                  ...companyDetails,
                  address: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Homepage">
            <Input
              value={companyDetails.homepage}
              onChange={(e) =>
                setCompanyDetails({
                  ...companyDetails,
                  homepage: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              type="email"
              value={companyDetails.email}
              onChange={(e) =>
                setCompanyDetails({ ...companyDetails, email: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Phone Number">
            <Input
              type="tel"
              value={companyDetails.phoneNumber}
              onChange={(e) =>
                setCompanyDetails({
                  ...companyDetails,
                  phoneNumber: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Physical Person">
            <Input
              value={companyDetails.contactPerson}
              onChange={(e) =>
                setCompanyDetails({
                  ...companyDetails,
                  contactPerson: e.target.value,
                })
              }
            />
          </Form.Item>

          <h3 className="mb-3">Product Details</h3>
          <Form.Item label="Description">
            <Input
              value={productDetails.description}
              onChange={(e) =>
                setProductDetails({
                  ...productDetails,
                  description: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Client">
            <Input
              value={productDetails.client}
              onChange={(e) =>
                setProductDetails({ ...productDetails, client: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Selling Method">
            <Input
              value={productDetails.sellingMethod}
              onChange={(e) =>
                setProductDetails({
                  ...productDetails,
                  sellingMethod: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="USPs">
            <Input
              value={productDetails.usps}
              onChange={(e) =>
                setProductDetails({ ...productDetails, usps: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Advantages">
            <Input
              value={productDetails.advantages}
              onChange={(e) =>
                setProductDetails({
                  ...productDetails,
                  advantages: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Special Features">
            <Input
              value={productDetails.specialFeatures}
              onChange={(e) =>
                setProductDetails({
                  ...productDetails,
                  specialFeatures: e.target.value,
                })
              }
            />
          </Form.Item>

          <h3 className="mb-3">{t("aiAgent")}</h3>
          <Form.Item label="Character">
            <Input
              value={aiAgentDetails.character}
              onChange={(e) =>
                setAiAgentDetails({
                  ...aiAgentDetails,
                  character: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Talking Speed">
            <Input
              value={aiAgentDetails.talkingSpeed}
              onChange={(e) =>
                setAiAgentDetails({
                  ...aiAgentDetails,
                  talkingSpeed: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Tone">
            <Input
              value={aiAgentDetails.tone}
              onChange={(e) =>
                setAiAgentDetails({ ...aiAgentDetails, tone: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Voice Sound">
            <Input
              value={aiAgentDetails.voiceSound}
              onChange={(e) =>
                setAiAgentDetails({
                  ...aiAgentDetails,
                  voiceSound: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="Important Emotions">
            <Input
              value={aiAgentDetails.importantEmotions}
              onChange={(e) =>
                setAiAgentDetails({
                  ...aiAgentDetails,
                  importantEmotions: e.target.value,
                })
              }
            />
          </Form.Item>

          <h3 className="mb-3">{t("clientObjections")}</h3>
          <Form.Item label="Client Objections">
            <TextArea
              rows={4}
              value={clientObjections}
              onChange={(e) => setClientObjections(e.target.value)}
            />
          </Form.Item>

          <Button type="primary" block onClick={handleSaveScript}>
            {t("saveScript")}
          </Button>
          <Button
            type="default"
            block
            onClick={() => setModalType("see")}
            style={{ marginTop: 10 }}
          >
            Back
          </Button>
        </Form>
      )}

      <Modal
        title="Edit Structured Script"
        visible={isShowScriptModalVisible}
        onOk={handleSaveStructuredScript}
        onCancel={() => setIsShowScriptModalVisible(false)}
        okText="Save"
        cancelText="Cancel"
      >
        <TextArea
          rows={10}
          value={structuredContent}
          onChange={(e) => setStructuredContent(e.target.value)}
        />
      </Modal>
    </div>
    // </Modal>
  );
};

export default ScriptManagement;
