import React, { useState } from "react";
import { Layout, Menu, Button, Tooltip, message } from "antd";
import {
  RadiusSettingOutlined,
  ShareAltOutlined,
  HomeOutlined,
  PlayCircleOutlined,
  DatabaseOutlined,
  CrownOutlined,
  LogoutOutlined,
  UserOutlined,
  GlobalOutlined,
  PhoneOutlined,
  LockOutlined,
  FileAddOutlined,
  BookOutlined,
  QuestionCircleOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import ScriptManagement from "../components/ScriptManagement";
import StartCampaign from "../components/StartCampaign";
import GetBatches from "../components/GetBatches";
import Analysis from "../components/AnalysisModal";
import PurchaseNumber from "../components/PurchaseNumber";
import AddScript from "../components/AddScript";
import Integrations from "../components/Integrations";
import FAQs from "../components/FAQs";
import Tutorials from "../components/Tutorials";

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const VIEWS = {
  HOME: "HOME",
  ADD_SCRIPT: "ADD_SCRIPT",
  MANAGE_SCRIPT: "MANAGE_SCRIPT",
  CAMPAIGN_HISTORY: "CAMPAIGN_HISTORY",
  START_CAMPAIGN: "START_CAMPAIGN",
  INTEGRATIONS: "INTEGRATIONS",
  FAQS: "FAQS",
  TUTORIALS: "TUTORIALS",
  PURCHASE_NUMBER: "PURCHASE_NUMBER",
};

const Homepage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const [currentView, setCurrentView] = useState(VIEWS.HOME);
  const [collapsed, setCollapsed] = useState(false);
  const [showScriptModal, setShowScriptModal] = useState(false);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [showPurchaseNumberModal, setShowPurchaseNumberModal] = useState(false);
  const [showAnalysisModal, setShowAnalysisModal] = useState(false);
  const [showCampaignHistory, setShowCampaignHistory] = useState(false);
  const [activeBatchId, setActiveBatchId] = useState(null);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "de" : "en";
    i18n.changeLanguage(newLanguage);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    navigate("/login");
    message.success(t("logout"));
  };

  const handleAccessRestrictedFeature = (featureAction) => {
    if (!user.isSubscribed) {
      message.info(t("upgradeToPro"));
      navigate("/pricing");
      return;
    }
    featureAction();
  };

  const getButtonStyle = () => ({
    backgroundColor: user.isSubscribed ? "" : "goldenrod",
    color: user.isSubscribed ? "" : "black",
    borderColor: user.isSubscribed ? "" : "goldenrod",
    width: "100%",
  });

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        width={290}
        breakpoint="md"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          insetInlineStart: 0,
          top: 0,
          bottom: 0,
          scrollbarWidth: "thin",
          scrollbarColor: "unset",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src="/logo.png"
            alt=""
            style={{
              width: "80%",
              marginTop: "20px",
              height: "auto",
            }}
          />
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          style={{ paddingTop: "10%" }}
        >
          <SubMenu
            key="scripts"
            icon={<RadiusSettingOutlined />}
            title={t("agentManagement")}
          >
            <Menu.Item key="1" icon={<FileAddOutlined />}>
              <Tooltip title={!user.isSubscribed ? t("upgradeToPro") : ""}>
                <Button
                  className="menu-button"
                  onClick={() =>
                    handleAccessRestrictedFeature(() =>
                      setCurrentView(VIEWS.ADD_SCRIPT)
                    )
                  }
                  style={getButtonStyle()}
                  icon={!user.isSubscribed && <LockOutlined />}
                  disabled={!user.isSubscribed}
                >
                  {t("addAgent")}
                </Button>
              </Tooltip>
            </Menu.Item>
            <Menu.Item key="2" icon={<RadiusSettingOutlined />}>
              <Tooltip title={!user.isSubscribed ? t("upgradeToPro") : ""}>
                <Button
                  className="menu-button"
                  onClick={() =>
                    handleAccessRestrictedFeature(() =>
                      setCurrentView(VIEWS.MANAGE_SCRIPT)
                    )
                  }
                  style={getButtonStyle()}
                  icon={!user.isSubscribed && <LockOutlined />}
                  disabled={!user.isSubscribed}
                >
                  {t("manageAgent")}
                </Button>
              </Tooltip>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="campaign"
            icon={<PlayCircleOutlined />}
            title={t("campaign")}
          >
            <Menu.Item key="3" icon={<PlayCircleOutlined />}>
              <Tooltip title={!user.isSubscribed ? t("upgradeToPro") : ""}>
                <Button
                  className="menu-button"
                  onClick={() =>
                    handleAccessRestrictedFeature(() => {
                      setShowCampaignModal(true);
                      setCurrentView(VIEWS.START_CAMPAIGN);
                    })
                  }
                  style={getButtonStyle()}
                  icon={!user.isSubscribed && <LockOutlined />}
                  disabled={!user.isSubscribed}
                >
                  {t("startCampaign")}
                </Button>
              </Tooltip>
            </Menu.Item>
            <Menu.Item key="4" icon={<DatabaseOutlined />}>
              <Tooltip title={!user.isSubscribed ? t("upgradeToPro") : ""}>
                <Button
                  className="menu-button"
                  onClick={() => {
                    setShowCampaignHistory(true);
                    setCurrentView(VIEWS.CAMPAIGN_HISTORY);
                  }}
                  style={getButtonStyle()}
                  icon={!user.isSubscribed && <LockOutlined />}
                  disabled={!user.isSubscribed}
                >
                  {t("campaignHistory")}
                </Button>
              </Tooltip>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="user" icon={<UserOutlined />} title={t("user")}>
            <Menu.Item key="5" icon={<UserOutlined />}>
              <Button
                className="menu-button"
                onClick={() => navigate("/user-profile")}
                style={getButtonStyle()}
              >
                {t("userProfile")}
              </Button>
            </Menu.Item>
            <Menu.Item key="6" icon={<ShareAltOutlined />}>
              <Button
                className="menu-button"
                onClick={() => {
                  handleAccessRestrictedFeature(() => {
                    setCurrentView(VIEWS.INTEGRATIONS);
                  });
                }}
                style={getButtonStyle()}
              >
                {t("integrations")}
              </Button>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="help"
            icon={<QuestionCircleOutlined />}
            title={t("help")}
          >
            <Menu.Item key="7" icon={<QuestionCircleOutlined />}>
              <Tooltip>
                <Button
                  className="menu-button"
                  onClick={() => setCurrentView(VIEWS.FAQS)}
                  style={getButtonStyle()}
                >
                  {t("FAQs")}
                </Button>
              </Tooltip>
            </Menu.Item>
            <Menu.Item key="8" icon={<BookOutlined />}>
              <Tooltip>
                <Button
                  className="menu-button"
                  onClick={() => setCurrentView(VIEWS.TUTORIALS)}
                  style={getButtonStyle()}
                >
                  {t("Tutorials")}
                </Button>
              </Tooltip>
            </Menu.Item>
            <Menu.Item key="9" icon={<ContactsOutlined />}>
              <Tooltip>
                <Button
                  className="menu-button"
                  onClick={() =>
                    message.info(
                      t("contactSupport") + " at support@ai-calls.de"
                    )
                  }
                  style={getButtonStyle()}
                >
                  {t("contactSupport")}
                </Button>
              </Tooltip>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="phoneManagement"
            icon={<PhoneOutlined />}
            title={t("phoneNumberManagement")}
          >
            <Menu.Item key="purchaseNumber" icon={<PhoneOutlined />}>
              <Button
                className="menu-button"
                onClick={() =>
                  handleAccessRestrictedFeature(() =>
                    setCurrentView(VIEWS.PURCHASE_NUMBER)
                  )
                }
                style={getButtonStyle()}
                icon={!user.isSubscribed && <LockOutlined />}
                disabled={!user.isSubscribed}
              >
                {t("purchaseNumber")}
              </Button>
            </Menu.Item>
            <Menu.Item key="inboundNumbers" icon={<PhoneOutlined />}>
              <Button
                className="menu-button"
                onClick={() => navigate("/inbound-homepage")}
                style={getButtonStyle()}
              >
                {t("inbound")}
              </Button>
            </Menu.Item>
          </SubMenu>

          <Menu.Item key="10" icon={<HomeOutlined />}>
            <Tooltip>
              <Button
                className="menu-button"
                onClick={() => setCurrentView(VIEWS.HOME)}
                style={getButtonStyle()}
              >
                {t("home")}
              </Button>
            </Tooltip>
          </Menu.Item>

          <Menu.Item key="11" icon={<CrownOutlined />}>
            <Button
              className="menu-button"
              onClick={() => navigate("/pricing")}
              style={getButtonStyle()}
            >
              {t("upgradeToPro")}
            </Button>
          </Menu.Item>

          <Menu.Item key="12" icon={<GlobalOutlined />}>
            <Button
              className="menu-button"
              onClick={toggleLanguage}
              style={getButtonStyle()}
            >
              {t("language")} {i18n.language === "en" ? "DE" : "EN"}
            </Button>
          </Menu.Item>
          <Menu.Item
            key="13"
            icon={<LogoutOutlined />}
            onClick={handleLogout}
            style={{ textAlign: "left" }}
          >
            {t("logout")}
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout
        className="site-layout"
        style={{
          marginInlineStart: collapsed ? 80 : 290,
          transition: "margin-inline-start 0.25s",
        }}
      >
        <Content style={{ margin: "0 16px", paddingTop: "1%" }}>
          {currentView === VIEWS.HOME && (
            <div style={{ textAlign: "center", marginTop: "15%" }}>
              <h2>{t("welcome")}</h2>
              <p>{t("welcomeMessage")}</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "50px",
                }}
              >
                <Tooltip title={!user.isSubscribed ? t("upgradeToPro") : ""}>
                  <Button
                    type="primary"
                    size="large"
                    style={{ ...getButtonStyle() }}
                    onClick={() =>
                      handleAccessRestrictedFeature(() => {
                        setShowCampaignModal(true);
                        setCurrentView(VIEWS.START_CAMPAIGN);
                      })
                    }
                    icon={!user.isSubscribed && <LockOutlined />}
                    disabled={!user.isSubscribed}
                  >
                    {t("startCampaign")}
                  </Button>
                </Tooltip>
                <Tooltip title={!user.isSubscribed ? t("upgradeToPro") : ""}>
                  <Button
                    size="large"
                    style={{ ...getButtonStyle() }}
                    onClick={() =>
                      handleAccessRestrictedFeature(() =>
                        setCurrentView(VIEWS.MANAGE_SCRIPT)
                      )
                    }
                    icon={!user.isSubscribed && <LockOutlined />}
                    disabled={!user.isSubscribed}
                  >
                    {t("manageScripts")}
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
          {currentView === VIEWS.CAMPAIGN_HISTORY && <GetBatches />}
          {currentView === VIEWS.START_CAMPAIGN && (
            <StartCampaign
              modalVisible={showCampaignModal}
              setModalVisible={setShowCampaignModal}
            />
          )}
          {currentView === VIEWS.ADD_SCRIPT && (
            <AddScript
              userId={user.userId}
              onSuccess={() => {
                setCurrentView(VIEWS.MANAGE_SCRIPT);
              }}
            />
          )}
          {currentView === VIEWS.MANAGE_SCRIPT && (
            <ScriptManagement userId={user.userId} />
          )}
          {currentView === VIEWS.INTEGRATIONS && (
            <Integrations userId={user.userId} />
          )}
          {currentView === VIEWS.FAQS && <FAQs />}
          {currentView === VIEWS.TUTORIALS && <Tutorials />}
          {currentView === VIEWS.PURCHASE_NUMBER && (
            <PurchaseNumber userId={user.userId} />
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Homepage;
