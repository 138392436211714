import React, { useContext, createContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { jwtDecode } from "jwt-decode"; // Correct import using named imports

const path = process.env.REACT_APP_API_PATH;

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

function useProvideAuth() {
  const [user, setUser] = useState(() => {
    const userToken = localStorage.getItem("userToken");
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    const userEmail = localStorage.getItem("userEmail");
    const isSubscribed = localStorage.getItem("isSubscribed") === "true";
    const userId = localStorage.getItem("userId"); // Retrieve userId from local storage

    return userToken
      ? { token: userToken, isAdmin, email: userEmail, isSubscribed, userId }
      : null;
  });

  const navigate = useNavigate();

  // Function to update user details
  const updateUser = (updates) => {
    setUser((currentUser) => ({ ...currentUser, ...updates }));
  };

  const signin = async (email, password) => {
    try {
      const response = await axios.post(
        `${path}/login`,
        { username: email, password: password },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data.token) {
        const decoded = jwtDecode(response.data.token); // Decode the token to get the payload

        // Store the data in local storage
        localStorage.setItem("userToken", response.data.token);
        localStorage.setItem("isAdmin", response.data.isadmin);
        localStorage.setItem("userEmail", email);
        localStorage.setItem("isSubscribed", response.data.isSubscribed);
        localStorage.setItem("userId", response.data.user_id); // Store user ID

        // Set the user state
        setUser({
          email,
          token: response.data.token,
          isAdmin: response.data.isadmin,
          isSubscribed: response.data.isSubscribed,
          userId: response.data.user_id, // Set user ID in state
        });

        toast.success("Logged in successfully");
        navigate(response.data.isadmin ? "/admin" : "/");
      } else {
        throw new Error(
          response.data.message || "Authentication failed, no token received"
        );
      }
    } catch (error) {
      const errorMessage = error.response?.data.error || "Failed to login";
      toast.error(errorMessage);
      throw new Error(errorMessage); // Handle errors properly
    }
  };

  const signout = () => {
    setUser(null);
    localStorage.removeItem("userToken");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("isSubscribed");
    localStorage.removeItem("userId"); // Remove user ID
    toast.success("Logged out successfully");
    navigate("/login");
  };

  return {
    user,
    signin,
    signout,
    updateUser,
  };
}

export default AuthContext;
