import React, { useEffect, useState } from "react";
import axios from "axios";
import { Collapse, Typography, message } from "antd";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;
const { Title } = Typography;

const FAQs = () => {
  const [faqs, setFaqs] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_PATH}/faqs`
        );
        setFaqs(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        message.error("Failed to load FAQs");
      }
    };

    fetchFaqs();
  }, []);

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <Title level={2} style={{ textAlign: "center", marginBottom: "30px" }}>
        {t("frequentlyAskedQuestions")}
      </Title>
      <Collapse accordion>
        {faqs.map((faq) => (
          <Panel header={faq.question} key={faq._id}>
            <p>{faq.answer}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default FAQs;
