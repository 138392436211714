import React, { useState, useEffect } from "react";
import { Button, Table, Card, Modal, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { CSVLink } from "react-csv";
import AnalysisModal from "../components/AnalysisModal"; // Import the AnalysisModal component
import { useTranslation } from "react-i18next";

const GetBatches = () => {
  const { t } = useTranslation();
  const [batches, setBatches] = useState([]);
  const [batchDetails, setBatchDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isBatchDetailsModalVisible, setIsBatchDetailsModalVisible] =
    useState(false);
  const [activeBatchId, setActiveBatchId] = useState(null);
  const [showAnalysisModal, setShowAnalysisModal] = useState(false);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showTranscriptModal, setShowTranscriptModal] = useState(false);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [currentSummary, setCurrentSummary] = useState("");
  const [currentTranscript, setCurrentTranscript] = useState("");
  const [callData, setCallData] = useState([]);
  const [isCallDetailsModalVisible, setIsCallDetailsModalVisible] =
    useState(false);

  const path = process.env.REACT_APP_API_PATH;

  // Fetch user ID from session or authentication context (Assuming user_id is available globally)
  const user_id = localStorage.getItem("userId");

  const getAllBatches = async () => {
    try {
      const response = await axios.get(`${path}/get-batches`, {
        params: { user_id }, // Pass user_id as a query parameter
      });
      if (response.data && Array.isArray(response.data.batches)) {
        setBatches(
          response.data.batches.map((batch) => ({
            ...batch,
            key: batch.batch_id,
            campaignId: batch.campaign_id,
            createdAt: batch.created_at,
          }))
        );
      } else {
        throw new Error("Unexpected data format");
      }
      message.success("Batches fetched successfully");
    } catch (error) {
      console.error("Error fetching batches:", error);
      message.error("Failed to fetch batches");
    }
  };

  const getBatchDetails = async (batchId) => {
    try {
      const response = await axios.get(`${path}/batch-details/${batchId}`, {
        params: { user_id }, // Pass user_id as a query parameter
      });
      setBatchDetails(response.data);
      setIsBatchDetailsModalVisible(true);
      message.success("Batch details fetched successfully");
    } catch (error) {
      message.error("Failed to fetch batch details");
      console.error("Batch details error:", error);
    }
  };

  const handleOpenAnalysisModal = (batchId) => {
    setActiveBatchId(batchId);
    setShowAnalysisModal(true);
  };

  const ResultsModal = () => {
    if (!analysisResults || !showResultsModal) return null;

    const columns = Array.isArray(analysisResults.questions)
      ? analysisResults.questions.map((question, index) => ({
          title: question[0] || `Question ${index + 1}`,
          dataIndex: `question_${index}`,
          key: `question_${index}`,
          render: (text, record) =>
            record[`question_${index}`] !== null &&
            record[`question_${index}`] !== undefined
              ? record[`question_${index}`].toString()
              : "N/A",
        }))
      : [];

    const dataSource = analysisResults.answers
      ? Object.keys(analysisResults.answers).map((callId) => {
          const answers = analysisResults.answers[callId];
          if (!Array.isArray(answers)) return { key: callId };

          return {
            key: callId,
            ...answers.reduce((acc, answer, idx) => {
              acc[`question_${idx}`] = answer;
              return acc;
            }, {}),
          };
        })
      : [];

    return (
      <Modal
        title="Analysis Results"
        visible={showResultsModal}
        onOk={() => setShowResultsModal(false)}
        onCancel={() => setShowResultsModal(false)}
        footer={[
          <Button key="close" onClick={() => setShowResultsModal(false)}>
            Close
          </Button>,
          <CSVLink
            key="download"
            data={dataSource.length ? dataSource : [[]]} // Ensure data is at least an array of arrays
            headers={columns.map((col) => ({
              label: col.title,
              key: col.dataIndex,
            }))}
            filename={`analysis_results_${moment().format(
              "YYYYMMDD_HHmmss"
            )}.csv`}
          >
            <Button type="primary">Download CSV</Button>
          </CSVLink>,
        ]}
      >
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{ pageSize: 5 }}
        />
      </Modal>
    );
  };

  const batchColumns = [
    {
      title: t("campaignId"),
      dataIndex: "campaignId",
      key: "campaignId",
      className: "full-width-column",
    },
    {
      title: t("createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
      className: "full-width-column",
    },
    {
      title: t("totalCall"),
      key: "details",
      render: (_, record) => (
        <Button onClick={() => getBatchDetails(record.batch_id)}>
          Get Details
        </Button>
      ),
      className: "full-width-column",
    },
    {
      title: t("analysis"),
      key: "analyze",
      render: (_, record) => (
        <Button onClick={() => handleOpenAnalysisModal(record.batch_id)}>
          Generate Analysis
        </Button>
      ),
      className: "full-width-column",
    },
  ];

  const renderBatchDetailsModal = () => {
    return (
      <Modal
        title={t("batchDetail")}
        visible={isBatchDetailsModalVisible}
        onOk={() => setIsBatchDetailsModalVisible(false)}
        onCancel={() => setIsBatchDetailsModalVisible(false)}
        footer={[
          <Button
            key="back"
            onClick={() => setIsBatchDetailsModalVisible(false)}
          >
            {t("close")}
          </Button>,
        ]}
      >
        {batchDetails ? (
          <div>
            <p>
              <strong>{t("campaignId")}:</strong>{" "}
              {batchDetails.batch_params.campaign_id}
            </p>
            <p>
              <strong>{t("createdAt")}:</strong>{" "}
              {moment(batchDetails.batch_params.created_at).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
            </p>
            <p>
              <strong>{t("totalCall")}:</strong> {batchDetails.analysis.total_calls}
            </p>
            <p>
              <strong>{t("completedCall")}:</strong>{" "}
              {batchDetails.analysis.completed_calls}
            </p>
            <p>
              <strong>{t("inProgressCall")}:</strong>{" "}
              {batchDetails.analysis.in_progress_calls}
            </p>
            <Button
              onClick={() => showCallDetailsModal(batchDetails.call_data)}
            >
              {t("callDetails")}
            </Button>
          </div>
        ) : (
          <p>{t("noCallDetails")}</p>
        )}
      </Modal>
    );
  };

  const handleShowSummary = (summary) => {
    setCurrentSummary(summary);
    setShowSummaryModal(true);
  };

  const handleShowTranscript = (transcript) => {
    setCurrentTranscript(transcript);
    setShowTranscriptModal(true);
  };

  const SummaryModal = () => (
    <Modal
      title="Call Summary"
      visible={showSummaryModal}
      onOk={() => setShowSummaryModal(false)}
      onCancel={() => setShowSummaryModal(false)}
      footer={[
        <Button key="close" onClick={() => setShowSummaryModal(false)}>
          Close
        </Button>,
      ]}
    >
      <p>{currentSummary}</p>
    </Modal>
  );

  const TranscriptModal = () => {
    const transcriptParagraphs = currentTranscript
      .split("\n")
      .map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));

    return (
      <Modal
        title="Call Transcript"
        visible={showTranscriptModal}
        onOk={() => setShowTranscriptModal(false)}
        onCancel={() => setShowTranscriptModal(false)}
        footer={[
          <Button key="close" onClick={() => setShowTranscriptModal(false)}>
            Close
          </Button>,
        ]}
      >
        <div>{transcriptParagraphs}</div>
      </Modal>
    );
  };

  const showCallDetailsModal = (callData) => {
    setCallData(callData);
    setIsCallDetailsModalVisible(true);
  };

  const renderCallDetailsModal = () => {
    if (!callData || !Array.isArray(callData)) {
      return null; // Return early if callData is null, undefined, or not an array
    }

    const columns = [
      { title: "Call ID", dataIndex: "call_id", key: "call_id", width: 150 },
      { title: "From", dataIndex: "from", key: "from", width: 150 },
      { title: "To", dataIndex: "to", key: "to", width: 150 },
      {
        title: "Duration",
        dataIndex: "call_length",
        key: "call_length",
        width: 200,
        render: (text) => (text ? `${text.toFixed(2)} seconds` : "N/A"),
      },
      {
        title: "Completed",
        dataIndex: "completed",
        key: "completed",
        width: 150,
        render: (completed) => (completed ? "Yes" : "No"),
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        width: 200,
        render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "End At",
        dataIndex: "end_at",
        key: "end_at",
        width: 200,
        render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        width: 150,
        render: (price) => (price ? `$${price.toFixed(2)}` : "N/A"),
      },
      {
        title: "Summary",
        dataIndex: "summary",
        key: "summary",
        width: 200,
        render: (text) => (
          <Button onClick={() => handleShowSummary(text)}>View Summary</Button>
        ),
      },
      {
        title: "Transcript",
        dataIndex: "concatenated_transcript",
        key: "concatenated_transcript",
        width: 200,
        render: (text) => (
          <Button onClick={() => handleShowTranscript(text)}>
            View Transcript
          </Button>
        ),
      },
      {
        title: "Recording",
        dataIndex: "recording_url",
        key: "recording_url",
        width: 200,
        render: (url) => (
          <a href={url} target="_blank" rel="noopener noreferrer">
            Listen
          </a>
        ),
      },
    ];

    return (
      <Modal
        title="Call Details"
        visible={isCallDetailsModalVisible}
        onOk={() => setIsCallDetailsModalVisible(false)}
        width={"90%"}
        onCancel={() => setIsCallDetailsModalVisible(false)}
        footer={[
          <Button
            key="back"
            onClick={() => setIsCallDetailsModalVisible(false)}
          >
            Close
          </Button>,
          <CSVLink
            data={callData.length ? callData : []} // Safely check length and provide fallback
            headers={columns.map((col) => ({
              label: col.title,
              key: col.dataIndex,
            }))}
            filename={`call_details_${moment().format("YYYYMMDD_HHmmss")}.csv`}
          >
            <Button type="primary">Download CSV</Button>
          </CSVLink>,
        ]}
      >
        <Table
          dataSource={callData}
          columns={columns}
          pagination={{ pageSize: 5 }}
          scroll={{ x: "max-content" }}
          tableLayout="fixed"
          style={{ whiteSpace: "nowrap" }}
        />
      </Modal>
    );
  };

  useEffect(() => {
    getAllBatches();
  }, []);

  return (
    <div>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <h1>{t("campaignHistory")}</h1>
        <Table
          dataSource={batches}
          columns={batchColumns}
          pagination={{ pageSize: 5 }}
          scroll={{ x: "max-content" }}
        />
      </div>

      {renderBatchDetailsModal()}
      {renderCallDetailsModal()}
      <SummaryModal />
      <TranscriptModal />
      <ResultsModal />

      {/* Analysis Modal for generating batch analysis */}
      <AnalysisModal
        batchId={activeBatchId}
        showAnalysisModal={showAnalysisModal}
        setShowAnalysisModal={setShowAnalysisModal}
        setAnalysisResults={setAnalysisResults}
      />
    </div>
  );
};

export default GetBatches;
