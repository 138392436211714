import React from "react";
import CSVReader from "react-csv-reader";
import { message } from "antd";
import { useTranslation } from "react-i18next";

const UploadCSV = ({ setCsvData, setCsvFile }) => {
  const { t } = useTranslation();

  const handleForce = (data, fileInfo) => {
    try {
      if (!data || data.length === 0 || !Array.isArray(data[0])) {
        throw new Error("Invalid or empty CSV file");
      }

      const headers = data[0];
      if (!headers.includes("name") || !headers.includes("phone_number")) {
        throw new Error(
          "CSV file must contain 'name' and 'phone_number' headers"
        );
      }

      const transformedData = data.slice(1).map((row) => {
        let rowData = {};
        row.forEach((cell, idx) => {
          rowData[headers[idx]] = cell.trim();
        });
        return rowData;
      });

      const uniqueData = [];
      const phoneNumbers = new Set();
      transformedData.forEach((row) => {
        if (row.phone_number && !phoneNumbers.has(row.phone_number)) {
          phoneNumbers.add(row.phone_number);
          uniqueData.push(row);
        }
      });

      // Create a Blob object to ensure the file is correctly handled
      const csvContent = data.map((row) => row.join(",")).join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const file = new File([blob], fileInfo.name, { type: "text/csv" });

      setCsvData(uniqueData);
      setCsvFile(file); // Use the correct File object
      message.success(`${fileInfo.name} ${t("fileUploadedSuccessfully")}`);
    } catch (error) {
      console.error("Error processing CSV file:", error);
      message.error(`${t("errorProcessingFile")}: ${error.message}`);
    }
  };

  const handleError = (error) => {
    console.error("Error uploading file:", error);
    message.error(t("errorUploadingFile"));
  };

  return (
    <div>
      <CSVReader
        onFileLoaded={handleForce}
        onError={handleError}
        inputId="csv-input"
        inputStyle={{ display: "block", margin: "10px 0" }}
      />
    </div>
  );
};

export default UploadCSV;
