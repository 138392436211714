import React, { useEffect, useState } from "react";
import axios from "axios";
import { List, Typography, message, Card, Modal } from "antd";
import { useTranslation } from "react-i18next";

const { Title, Paragraph } = Typography;

const Tutorials = () => {
  const [tutorials, setTutorials] = useState([]);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchTutorials();
  }, []);

  const fetchTutorials = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/tutorials`
      );
      setTutorials(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      message.error("Failed to fetch tutorials: " + error.message);
    }
  };

  const showModal = (tutorial) => {
    setSelectedTutorial(tutorial);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedTutorial(null);
  };

  return (
    <div style={{ padding: "24px" }}>
      <Title level={2}>{t("Tutorials")}</Title>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
        {tutorials.map((tutorial) => (
          <Card
            key={tutorial._id}
            title={tutorial.title}
            bordered={false}
            style={{ width: 300, cursor: "pointer" }}
            onClick={() => showModal(tutorial)}
          >
            <Paragraph ellipsis={{ rows: 2 }}>
              {tutorial.content.substring(0, 100)}...
            </Paragraph>
          </Card>
        ))}
      </div>

      <Modal
        title={selectedTutorial?.title}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Typography>
          <Paragraph>{selectedTutorial?.content}</Paragraph>
        </Typography>
      </Modal>
    </div>
  );
};

export default Tutorials;
