import React, { useState, useCallback, memo } from "react";
import { Modal, Button, Input, Select, Table, message } from "antd";
import axios from "axios";
import { CSVLink } from "react-csv";
import { useAuth } from "../context/AuthContext";

const AnalysisModal = memo(
  ({
    batchId,
    user_Id, // Add userId as a prop
    showAnalysisModal,
    setShowAnalysisModal,
    setAnalysisResults,
  }) => {
    const [localGoal, setLocalGoal] = useState("");
    const [localQuestions, setLocalQuestions] = useState([
      { question: "", type: "string" },
    ]);
    const [analysisData, setAnalysisData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();

    const handleCloseModal = useCallback(() => {
      setShowAnalysisModal(false);
      setAnalysisData(null);
      setLocalGoal("");
      setLocalQuestions([{ question: "", type: "string" }]);
    }, [setShowAnalysisModal]);

    const handleAddQuestion = useCallback(() => {
      setLocalQuestions([...localQuestions, { question: "", type: "string" }]);
    }, [localQuestions]);

    const handleRemoveQuestion = useCallback(
      (index) => {
        const filteredQuestions = localQuestions.filter(
          (_, idx) => idx !== index
        );
        setLocalQuestions(filteredQuestions);
      },
      [localQuestions]
    );

    const handleQuestionChange = useCallback(
      (index, value) => {
        const updatedQuestions = localQuestions.map((item, idx) => {
          if (index === idx) {
            return { ...item, question: value };
          }
          return item;
        });
        setLocalQuestions(updatedQuestions);
      },
      [localQuestions]
    );

    const handleTypeChange = useCallback(
      (index, value) => {
        const updatedQuestions = localQuestions.map((item, idx) => {
          if (index === idx) {
            return { ...item, type: value };
          }
          return item;
        });
        setLocalQuestions(updatedQuestions);
      },
      [localQuestions]
    );

    const handleSubmit = async () => {
      setLoading(true);
      const formattedQuestions = localQuestions.map((q) => [
        q.question,
        q.type,
      ]);

      const payload = {
        goal: localGoal,
        questions: formattedQuestions,
        user_id: user.userId, // Include userId in the payload
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_PATH}/analyze-batch/${batchId}`,
          payload
        );
        if (response.data && response.status === 200) {
          console.log("Analysis Response Data:", response.data);
          setAnalysisResults(response.data);
          setAnalysisData(response.data);
          message.success("Analysis completed successfully!");
        } else {
          throw new Error("Server responded but did not return expected data.");
        }
      } catch (error) {
        message.error(
          "Failed to fetch analysis results: " +
            (error.response ? error.response.data.message : error.message)
        );
      } finally {
        setLoading(false);
      }
    };

    const generateCSVData = () => {
      if (!analysisData) return [];

      const columns = analysisData.questions.map((question, index) => ({
        label: question[0],
        key: `question_${index}`,
      }));

      const dataSource = Object.keys(analysisData.answers).map((callId) => {
        const answers = analysisData.answers[callId];
        const record = { key: callId };
        answers.forEach((answer, index) => {
          record[`question_${index}`] =
            answer !== null ? answer.toString() : "N/A";
        });
        return record;
      });

      return { columns, dataSource };
    };

    const renderResultsTable = () => {
      if (!analysisData) return null;

      const columns = analysisData.questions.map((question, index) => ({
        title: question[0],
        dataIndex: `question_${index}`,
        key: `question_${index}`,
      }));

      const dataSource = Object.keys(analysisData.answers).map((callId) => {
        const answers = analysisData.answers[callId];
        const record = { key: callId, callId }; // Include callId for debugging
        answers.forEach((answer, index) => {
          record[`question_${index}`] =
            answer !== null ? answer.toString() : "N/A";
        });
        return record;
      });

      console.log("Columns for Table:", columns);
      console.log("Data Source for Table:", dataSource);

      return (
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{ pageSize: 5 }}
        />
      );
    };

    return (
      <Modal
        title="Analyze Batch"
        open={showAnalysisModal}
        onCancel={handleCloseModal}
        footer={[
          <Button key="back" onClick={handleCloseModal}>
            Close
          </Button>,
          analysisData && (
            <CSVLink
              key="download"
              data={generateCSVData().dataSource}
              headers={generateCSVData().columns}
              filename={`analysis_results_${new Date().toISOString()}.csv`}
            >
              <Button type="primary">Download CSV</Button>
            </CSVLink>
          ),
          !analysisData && (
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              Submit
            </Button>
          ),
        ]}
      >
        {!analysisData ? (
          <>
            <Input
              placeholder="Enter Analysis Goal"
              value={localGoal}
              onChange={(e) => setLocalGoal(e.target.value)}
              style={{ marginBottom: 10 }}
              disabled={loading}
            />
            {localQuestions.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <Input
                  style={{ flex: 1, marginRight: 10 }}
                  placeholder="Enter question"
                  value={item.question}
                  onChange={(e) => handleQuestionChange(index, e.target.value)}
                  disabled={loading}
                />
                <Select
                  style={{ width: 120, marginRight: 10 }}
                  value={item.type}
                  onChange={(value) => handleTypeChange(index, value)}
                  disabled={loading}
                >
                  <Select.Option value="string">String</Select.Option>
                  <Select.Option value="yes or no">Yes/No</Select.Option>
                  <Select.Option value="boolean">Boolean</Select.Option>
                </Select>
                {!loading && (
                  <Button danger onClick={() => handleRemoveQuestion(index)}>
                    Remove
                  </Button>
                )}
              </div>
            ))}
            {!loading && (
              <Button type="dashed" onClick={handleAddQuestion} block>
                Add Question
              </Button>
            )}
          </>
        ) : (
          renderResultsTable()
        )}
      </Modal>
    );
  }
);

export default AnalysisModal;
