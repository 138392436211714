import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Button,
  Select,
  Spin,
  Card,
  Typography,
  message,
  Modal,
  Input,
  Drawer,
  Table,
  Tooltip,
  Switch,
  Form,
  Space,
  Popconfirm,
} from "antd";
import {
  PlusOutlined,
  PhoneOutlined,
  BarChartOutlined,
  DatabaseOutlined,
  LogoutOutlined,
  ArrowLeftOutlined,
  NumberOutlined,
  InfoCircleOutlined,
  RobotOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/AuthContext";
import ScriptManagementInbound from "../components/ScriptManagementInbound"; // Adjust the path as necessary
import AddScript from "../components/AddScriptInbound"; // Import the AddScript component

const { Header, Content, Sider } = Layout;
const { Title } = Typography;

const InboundHomepage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { SubMenu } = Menu;

  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [normalVoices, setNormalVoices] = useState([]);
  const [premiumVoices, setPremiumVoices] = useState([]);
  const [voiceCategory, setVoiceCategory] = useState("normal"); // New state for voice category
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [analysisData, setAnalysisData] = useState(null);
  const [callDetails, setCallDetails] = useState([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [drawerContent, setDrawerContent] = useState("");
  const [voices, setVoices] = useState([]);
  const [scripts, setScripts] = useState([]);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [isScriptModalVisible, setIsScriptModalVisible] = useState(false);
  const [isAddScriptModalVisible, setIsAddScriptModalVisible] = useState(false);
  const [agentData, setAgentData] = useState({
    name: "",
    script: "",
    scriptContent: "",
    first_sentence: "",
    voice: "",
  });
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState([]);
  const [openAssignNumbersModal, setOpenAssignNumbersModal] = useState(false);
  const [isCreateAgentModalVisible, setIsCreateAgentModalVisible] =
    useState(false);
  const [integrations, setIntegrations] = useState({});
  const [sms, setSms] = useState({ enabled: false, message: "" });
  const [email, setEmail] = useState({ enabled: false, message: "" });
  const [calendly, setCalendly] = useState({ enabled: false });
  const [companyEmail, setCompanyEmail] = useState(""); // New state for company email
  const [isManageAgentsModalVisible, setIsManageAgentsModalVisible] =
    useState(false);

  const path = process.env.REACT_APP_API_PATH;

  useEffect(() => {
    fetchNumbers();
    fetchAgents();
    fetchVoices();
    fetchScripts();
    fetchIntegrations();
  }, []);

  const fetchNumbers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${path}/inbound`, {
        params: { user_id: user.userId },
      });
      const fetchedNumbers = response.data.inbound_numbers || [];
      setNumbers(fetchedNumbers);
      message.success(t("numbersFetched"));
    } catch (error) {
      console.error("Error fetching numbers:", error);
      message.error(t("failedToFetchNumbers"));
    } finally {
      setLoading(false);
    }
  };

  const fetchAgents = async () => {
    if (!user?.userId) {
      message.error("User ID is required to fetch agents.");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/get-all-inbound-agents`,
        {
          params: { user_id: user.userId },
        }
      );
      setAgents(response.data.agents);
    } catch (error) {
      console.error("Failed to fetch agents:", error);
      message.error("Failed to fetch agents.");
    }
  };

  const fetchVoices = async () => {
    try {
      // Fetch normal voices
      const normalResponse = await axios.get(`${path}/voices`);
      setNormalVoices(normalResponse.data.voices || []);

      // Fetch premium voices
      const premiumResponse = await axios.get(`${path}/admin/voices`);
      setPremiumVoices(premiumResponse.data || []);

      message.success("Voices fetched successfully!");
    } catch (error) {
      console.error("Failed to fetch voices:", error);
      message.error("Failed to fetch voices.");
    }
  };

  const fetchScripts = async () => {
    if (!user?.userId) {
      message.error("User ID is required to fetch scripts.");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/user_scripts_inbound`,
        {
          params: { user_id: user.userId },
        }
      );
      setScripts(response.data);
      message.success("Scripts fetched successfully!");
    } catch (error) {
      console.error("Failed to fetch scripts:", error);
      message.error("Failed to fetch scripts.");
    }
  };

  const fetchIntegrations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/user/integrations`,
        {
          params: { user_id: user.userId },
        }
      );
      setIntegrations(response.data);
    } catch (error) {
      console.error("Failed to fetch integrations:", error);
      message.error("Failed to fetch user integrations.");
    }
  };

  const fetchAnalysisData = async (number) => {
    setLoading(true);
    try {
      const response = await axios.get(`${path}/call_summary`, {
        params: { phone_number: number, user_id: user.userId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setAnalysisData(response.data);
      message.success(t("analysisDataFetched"));
    } catch (error) {
      console.error("Error fetching analysis data:", error);
      message.error(t("failedToFetchAnalysisData"));
    } finally {
      setLoading(false);
    }
  };

  const fetchCallDetails = async (number) => {
    setLoading(true);
    try {
      const response = await axios.get(`${path}/list_calls`, {
        params: { phone_number: number, user_id: user.userId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setCallDetails(response.data.calls || []);
      setIsDetailsModalVisible(true);
      message.success(t("callDetailsFetched"));
    } catch (error) {
      console.error("Error fetching call details:", error);
      message.error(t("failedToFetchCallDetails"));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAgent = async (agentId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_PATH}/delete-inbound-agent`,
        {
          data: { agent_id: agentId, user_id: user.userId },
        }
      );
      if (response.status === 200) {
        message.success("agent Deleted");
        // Refresh agents list after deletion
        fetchAgents();
      } else {
        message.error(t("failedToDeleteAgent"));
      }
    } catch (error) {
      message.error(t("failedToDeleteAgent"));
      console.error("Error deleting agent:", error);
    }
  };

  const handleCreateAgent = async () => {
    if (!agentData.name) {
      message.error("Agent name is required");
      return;
    }

    if (!agentData.script) {
      message.error("You must select a script for the agent");
      return;
    }

    if (!agentData.voice) {
      message.error("You must select a voice for the agent");
      return;
    }

    if (sms.enabled && !sms.message) {
      message.error("Please provide an SMS message or disable the SMS option");
      return;
    }

    if (email.enabled && !email.message) {
      message.error(
        "Please provide an email message or disable the email option"
      );
      return;
    }

    if (email.enabled && !companyEmail) {
      message.error("Please provide a company email for AI emailing");
      return;
    }

    try {
      setLoading(true);

      const agentPayload = {
        user_id: user.userId,
        agent_name: agentData.name,
        prompt: agentData.scriptContent,
        voice: agentData.voice,
        first_sentence: agentData.first_sentence,
        sms_enable: sms.enabled,
        sms_text: sms.enabled ? sms.message : null,
        email_enable: email.enabled,
        email_text: email.enabled ? email.message : null,
        calendly_enable: calendly.enabled,
        company_email: email.enabled ? companyEmail : null, // Include company email if AI emailing is enabled
      };

      const response = await axios.post(
        `${path}/save-inbound-agent`,
        agentPayload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      if (response.status === 200) {
        message.success("Agent created successfully");
        setIsCreateAgentModalVisible(false);
        fetchAgents();
      } else {
        throw new Error(response.data?.message || "Failed to create agent");
      }
    } catch (error) {
      message.error(
        "Failed to create agent: " +
          (error.response?.data?.message || error.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    navigate("/login");
    message.success(t("loggedOut"));
  };

  const assignNumbers = async () => {
    if (!selectedAgent || selectedPhoneNumbers.length === 0) {
      message.error("Please select an agent and phone numbers");
      return;
    }

    try {
      const response = await axios.post(`${path}/apply-inbound-agent`, {
        user_id: user.userId,
        agent_id: selectedAgent,
        phone_numbers: selectedPhoneNumbers,
      });

      if (response.status === 200) {
        message.success("Numbers assigned successfully");
        setOpenAssignNumbersModal(false);
        setSelectedAgent(null);
        setSelectedPhoneNumbers([]);
      } else {
        message.error("Failed to assign numbers to agent");
      }
    } catch (error) {
      message.error("Failed to assign numbers to agent");
    }
  };

  const openCreateAgentModal = () => {
    setIsCreateAgentModalVisible(true);
  };

  const distributionByTime = analysisData
    ? [
        {
          time: t("morning"),
          count: analysisData.call_distribution_by_time.morning,
        },
        {
          time: t("afternoon"),
          count: analysisData.call_distribution_by_time.afternoon,
        },
        {
          time: t("evening"),
          count: analysisData.call_distribution_by_time.evening,
        },
        {
          time: t("night"),
          count: analysisData.call_distribution_by_time.night,
        },
      ]
    : [];

  const endedBy = analysisData
    ? [
        {
          role: t("assistant"),
          count: analysisData.call_ended_by_summary.ASSISTANT,
        },
        { role: t("user"), count: analysisData.call_ended_by_summary.USER },
      ]
    : [];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        width={290}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src="/logo.png"
            alt=""
            style={{
              width: "80%",
              marginTop: "20px",
              height: "auto",
            }}
          />
        </div>

        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          style={{ paddingTop: "10%" }}
        >
          {/* Number Management SubMenu */}
          <SubMenu
            key="sub1"
            icon={<NumberOutlined />}
            title={t("numberManagement")}
          >
            <Menu.Item key="1" icon={<PhoneOutlined />}>
              <Select
                style={{ width: "100%" }}
                placeholder={t("selectNumber")}
                onChange={(value) => setSelectedNumber(value)}
              >
                {numbers.map((number) => (
                  <Select.Option
                    key={number.phone_number}
                    value={number.phone_number}
                  >
                    {number.phone_number}
                  </Select.Option>
                ))}
              </Select>
            </Menu.Item>
            <Menu.Item key="2">
              <Button
                onClick={() => {
                  setOpenAssignNumbersModal(true);
                }}
              >
                {t("assignNumbers")}
              </Button>
            </Menu.Item>
          </SubMenu>

          {/* Agent Management SubMenu */}
          {/* Agent Management SubMenu */}
          <SubMenu
            key="sub2"
            icon={<RobotOutlined />}
            title={t("agentManagement")}
          >
            <Menu.Item key="3">
              <Button onClick={() => setIsCreateAgentModalVisible(true)}>
                {t("createAgent")}
              </Button>
            </Menu.Item>
            <Menu.Item key="10">
              <Button onClick={() => setIsManageAgentsModalVisible(true)}>
                {t("manageAgents")}
              </Button>
            </Menu.Item>
          </SubMenu>

          {/* Analysis SubMenu */}
          <SubMenu key="sub3" icon={<BarChartOutlined />} title={t("analysis")}>
            <Menu.Item key="4">
              <Button
                onClick={() => {
                  if (selectedNumber) {
                    fetchAnalysisData(selectedNumber);
                  } else {
                    message.warning(t("selectNumberFirst"));
                  }
                }}
              >
                {t("analyze")}
              </Button>
            </Menu.Item>
            <Menu.Item key="5">
              <Button
                onClick={() => {
                  if (selectedNumber) {
                    fetchCallDetails(selectedNumber);
                  } else {
                    message.warning(t("selectNumberFirst"));
                  }
                }}
              >
                {t("leads")}
              </Button>
            </Menu.Item>
          </SubMenu>

          {/* Script Management SubMenu */}
          <SubMenu
            key="sub4"
            icon={<DatabaseOutlined />}
            title={t("scriptManagement")}
          >
            <Menu.Item key="6">
              <Button onClick={() => setIsScriptModalVisible(true)}>
                {t("manageScripts")}
              </Button>
            </Menu.Item>
            <Menu.Item key="7">
              <Button onClick={() => setIsAddScriptModalVisible(true)}>
                {t("addScript")}
              </Button>
            </Menu.Item>
          </SubMenu>

          {/* Logout and Back */}
          <Menu.Item key="8" icon={<ArrowLeftOutlined />}>
            <Button onClick={() => navigate("/")}>{t("backToOutbound")}</Button>
          </Menu.Item>
          <Menu.Item key="9" icon={<LogoutOutlined />} onClick={handleLogout}>
            {t("logout")}
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: "#fff", padding: 0 }}>
          <Title level={2} style={{ textAlign: "center" }}>
            {t("inboundCallManagement")}
          </Title>
        </Header>
        <Content style={{ margin: "0 16px", paddingTop: "20px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {analysisData && (
              <div className="flex flex-col gap-8">
                <Title level={3}>{t("analytics")}</Title>
                <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-2">
                  <Card>
                    <Card.Meta
                      title={t("totalPrice")}
                      description={`$${analysisData.total_price.toFixed(2)}`}
                    />
                  </Card>
                  <Card>
                    <Card.Meta
                      title={t("topCaller")}
                      description={
                        analysisData &&
                        analysisData.top_callers &&
                        analysisData.top_callers.length > 0
                          ? analysisData.top_callers[0][0]
                          : t("noData")
                      }
                    />
                    <p>
                      {t("count")}:{" "}
                      {analysisData &&
                      analysisData.top_callers &&
                      analysisData.top_callers.length > 0
                        ? analysisData.top_callers[0][1]
                        : t("noData")}
                    </p>
                  </Card>

                  <Card>
                    <Card.Meta
                      title={t("totalDuration")}
                      description={`${analysisData.total_duration.toFixed(
                        2
                      )} ${t("seconds")}`}
                    />
                  </Card>
                  <Card>
                    <Card.Meta
                      title={t("averageDuration")}
                      description={`${analysisData.average_duration.toFixed(
                        2
                      )} ${t("seconds")}`}
                    />
                  </Card>
                  <Card>
                    <Card.Meta title={t("distributionByTime")} />
                    <ResponsiveContainer width="100%" height={180}>
                      <BarChart data={distributionByTime}>
                        <Bar dataKey="count" fill="#8884d8" />
                        <RechartsTooltip />
                        <XAxis dataKey="time" />
                        <YAxis />
                      </BarChart>
                    </ResponsiveContainer>
                  </Card>
                  <Card>
                    <Card.Meta title={t("callEndedBy")} />
                    <ResponsiveContainer width="100%" height={180}>
                      <BarChart data={endedBy}>
                        <Bar dataKey="count" fill="#82ca9d" />
                        <RechartsTooltip />
                        <XAxis dataKey="role" />
                        <YAxis />
                      </BarChart>
                    </ResponsiveContainer>
                  </Card>
                </div>
              </div>
            )}
            <Spin spinning={loading}>
              <Table
                dataSource={callDetails}
                pagination={{ pageSize: 5 }}
                scroll={{ x: "max-content" }} // Makes the table horizontally scrollable
              >
                <Table.Column
                  title={t("callId")}
                  dataIndex="call_id"
                  key="call_id"
                />
                <Table.Column title={t("from")} dataIndex="from" key="from" />
                <Table.Column title={t("to")} dataIndex="to" key="to" />
                <Table.Column
                  title={t("duration")}
                  dataIndex="call_length"
                  key="call_length"
                  render={(text) => `${text.toFixed(2)} ${t("seconds")}`}
                />
                <Table.Column
                  title={t("completed")}
                  dataIndex="completed"
                  key="completed"
                  render={(text) => (text ? t("yes") : t("no"))}
                />
                <Table.Column
                  title={t("createdAt")}
                  dataIndex="created_at"
                  key="created_at"
                  render={(text) => moment(text).format("YYYY-MM-DD HH:mm:ss")}
                />
                <Table.Column
                  title={t("price")}
                  dataIndex="price"
                  key="price"
                />

                {/* <Table.Column
                  title={t("summary")}
                  key="summary"
                  render={(text, record) => (
                    <Button
                      type="primary"
                      onClick={() => {
                        if (record.summary) {
                          console.log(
                            "Setting drawer content:",
                            record.summary
                          ); // Log the summary
                          setDrawerContent(record.summary); // Set the summary content in state
                          console.log("Opening drawer..."); // Log the drawer opening action
                          setIsDrawerVisible(true); // Show the drawer
                        } else {
                          console.log("No summary available"); // Log if there's no summary
                          message.warning(t("noSummaryAvailable")); // Show warning message
                        }
                      }}
                    >
                      {t("open")}
                    </Button>
                  )}
                /> */}
              </Table>
            </Spin>
            <Drawer
              title={t("callSummary")}
              placement="right"
              onClose={() => {
                console.log("Closing drawer...");
                setIsDrawerVisible(false); // Close the drawer
              }}
              visible={isDrawerVisible} // Control visibility with state
            >
              {drawerContent ? (
                <p>{drawerContent}</p> // Show the summary content
              ) : (
                <p>{t("noSummaryAvailable")}</p> // Fallback message
              )}
            </Drawer>

            <Modal
              title={t("addScript")}
              visible={isAddScriptModalVisible}
              onCancel={() => setIsAddScriptModalVisible(false)}
              footer={null}
              width={800}
            >
              <AddScript
                userId={user.userId}
                onSuccess={() => {
                  setIsAddScriptModalVisible(false);
                  fetchScripts(); // Refresh scripts list after adding
                }}
              />
            </Modal>

            {/* Script Management Modal */}
            <Modal
              title={t("manageScripts")}
              visible={isScriptModalVisible}
              onCancel={() => setIsScriptModalVisible(false)}
              footer={null}
              width={800}
            >
              <ScriptManagementInbound
                userId={user.userId}
                showScriptModal={isScriptModalVisible}
                setShowScriptModal={setIsScriptModalVisible}
              />
            </Modal>

            <Modal
              title={
                <span>
                  <PlusOutlined /> {t("createAgent")}
                </span>
              }
              visible={isCreateAgentModalVisible}
              onOk={handleCreateAgent}
              onCancel={() => setIsCreateAgentModalVisible(false)}
              footer={[
                <Button
                  key="cancel"
                  onClick={() => setIsCreateAgentModalVisible(false)}
                >
                  {t("cancel")}
                </Button>,
                <Button
                  key="createAgent"
                  type="primary"
                  onClick={handleCreateAgent}
                >
                  {t("saveAgent")}
                </Button>,
              ]}
            >
              <div style={{ marginBottom: 10 }}>
                <Input
                  placeholder={t("enterAgentName")}
                  style={{ marginBottom: "10px" }}
                  value={agentData.name}
                  onChange={(e) => {
                    setAgentData({ ...agentData, name: e.target.value });
                  }}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <Tooltip title={t("scriptTooltip")}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder={t("selectScript")}
                    value={agentData.script}
                    onChange={(value) => {
                      const selectedScript = scripts.find(
                        (script) => script._id === value
                      );
                      setAgentData({
                        ...agentData,
                        script: value,
                        scriptContent: selectedScript?.structured_content || "",
                        first_sentence: selectedScript?.greeting || "",
                      });
                    }}
                  >
                    {scripts.map((script) => (
                      <Select.Option key={script._id} value={script._id}>
                        {script.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Tooltip>
              </div>
              {agentData.script && (
                <>
                  <div style={{ marginBottom: 10 }}>
                    <Tooltip title={t("modifyScriptTooltip")}>
                      <Input.TextArea
                        placeholder={t("modifyScript")}
                        value={agentData.scriptContent}
                        onChange={(e) =>
                          setAgentData({
                            ...agentData,
                            scriptContent: e.target.value,
                          })
                        }
                        rows={4}
                      />
                    </Tooltip>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Button
                      icon={<RobotOutlined />}
                      type="default"
                      onClick={() =>
                        message.info("AI Script Upgrade triggered (stubbed).")
                      }
                    >
                      {t("aiUpgradeScript")}
                    </Button>
                  </div>
                </>
              )}
              <div style={{ marginBottom: 10 }}>
                <Tooltip title={t("firstSentenceTooltip")}>
                  <Input
                    placeholder={t("enterFirstSentence")}
                    value={agentData.first_sentence}
                    onChange={(e) =>
                      setAgentData({
                        ...agentData,
                        first_sentence: e.target.value,
                      })
                    }
                    style={{ marginBottom: 10 }}
                  />
                </Tooltip>
              </div>
              <div style={{ marginBottom: 10 }}>
                <Tooltip title={t("selectCategoryTitle")}>
                  <Select
                    placeholder={t("selectCategoryTitle")}
                    style={{ width: "100%", marginBottom: 10 }}
                    onChange={(value) => setVoiceCategory(value)}
                    value={voiceCategory}
                  >
                    <Select.Option value="normal">
                      {t("normalVoices")}
                    </Select.Option>
                    <Select.Option value="premium">
                      {t("premiumVoices")}
                    </Select.Option>
                  </Select>
                </Tooltip>
              </div>

              {/* Voice Selection */}
              <div style={{ marginBottom: 10 }}>
                <Tooltip title={t("voiceTooltip")}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder={t("selectVoice")}
                    value={agentData.voice || agentData.voice}
                    onChange={(value) =>
                      setAgentData({ ...agentData, voice: value })
                    }
                  >
                    {(voiceCategory === "normal"
                      ? normalVoices
                      : premiumVoices
                    ).map((voice) => (
                      <Select.Option
                        key={voice._id || voice.id}
                        value={voice._id || voice.id}
                      >
                        {voice.name} - {voice.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Tooltip>
              </div>
              <div style={{ marginBottom: 10 }}>
                <Form.Item
                  label={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {t("sms")}{" "}
                      <Tooltip title={t("enableSmsTooltip")}>
                        <InfoCircleOutlined style={{ marginLeft: 8 }} />
                      </Tooltip>
                      <Switch
                        checked={sms.enabled}
                        onChange={(checked) => {
                          if (checked && !integrations.twilio_encrypted_key) {
                            message.error(t("integrateSmsService"));
                            return;
                          }
                          setSms({ ...sms, enabled: checked });
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </span>
                  }
                >
                  {sms.enabled && (
                    <Input
                      placeholder={t("enterSmsMessage")}
                      value={sms.message}
                      onChange={(e) =>
                        setSms({ ...sms, message: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </div>
              <div style={{ marginBottom: 10 }}>
                <Form.Item
                  label={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {t("email")}{" "}
                      <Tooltip title={t("enableEmailTooltip")}>
                        <InfoCircleOutlined style={{ marginLeft: 8 }} />
                      </Tooltip>
                      <Switch
                        checked={email.enabled}
                        onChange={(checked) => {
                          if (checked && !integrations.resend_api_key) {
                            message.error(t("integrateEmailService"));
                            return;
                          }
                          setEmail({ ...email, enabled: checked });
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </span>
                  }
                >
                  {email.enabled && (
                    <Input
                      placeholder={t("enterEmailMessage")}
                      value={email.message}
                      onChange={(e) =>
                        setEmail({ ...email, message: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </div>
              <div style={{ marginBottom: 10 }}>
                <Form.Item
                  label={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {t("calendly")}{" "}
                      <Tooltip title={t("enableCalendlyTooltip")}>
                        <InfoCircleOutlined style={{ marginLeft: 8 }} />
                      </Tooltip>
                      <Switch
                        checked={calendly.enabled}
                        onChange={(checked) => {
                          if (checked && !integrations.calendly_url) {
                            message.error(t("integrateCalendlyService"));
                            return;
                          }
                          setCalendly({ ...calendly, enabled: checked });
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </span>
                  }
                />
              </div>
              {email.enabled && (
                <div style={{ marginBottom: 10 }}>
                  <Tooltip title={t("companyEmailTooltip")}>
                    <Input
                      placeholder={t("enterCompanyEmail")}
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                    />
                  </Tooltip>
                </div>
              )}
            </Modal>
            <Modal
              title={t("manageAgents")}
              visible={isManageAgentsModalVisible}
              onCancel={() => setIsManageAgentsModalVisible(false)}
              footer={null}
              width={800}
            >
              <Table
                dataSource={agents}
                rowKey="_id"
                pagination={{ pageSize: 5 }}
              >
                <Table.Column
                  title={t("agentName")}
                  dataIndex="agent_name"
                  key="agent_name"
                />
                <Table.Column
                  title={t("actions")}
                  key="actions"
                  render={(text, record) => (
                    <Space size="middle">
                      <Popconfirm
                        title={t("deleteAgentConfirmation")}
                        onConfirm={() => handleDeleteAgent(record._id)}
                        okText={t("yes")}
                        cancelText={t("no")}
                      >
                        <Tooltip title={t("deleteAgentTooltip")}>
                          <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                          >
                            {t("delete")}
                          </Button>
                        </Tooltip>
                      </Popconfirm>
                    </Space>
                  )}
                />
              </Table>
            </Modal>
            <Modal
              title={t("assignNumbersToAgent")}
              open={openAssignNumbersModal}
              onClose={() => {
                setOpenAssignNumbersModal(false);
              }}
              onCancel={() => {
                setOpenAssignNumbersModal(false);
              }}
              footer={false}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder={t("selectAgent")}
                  onChange={(value) => setSelectedAgent(value)}
                >
                  {agents.length !== 0 &&
                    agents?.map((agent) => (
                      <Select.Option key={agent._id} value={agent._id}>
                        {agent.agent_name}
                      </Select.Option>
                    ))}
                </Select>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={t("selectPhoneNumbers")}
                  onChange={(values) => {
                    setSelectedPhoneNumbers(values);
                  }}
                >
                  {numbers.map((number) => (
                    <Select.Option
                      key={number.phone_number}
                      value={number.phone_number}
                    >
                      {number.phone_number}
                    </Select.Option>
                  ))}
                </Select>
                <Button onClick={assignNumbers}>{t("assignNumbers")}</Button>
              </div>
            </Modal>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default InboundHomepage;
