import React from "react";
import { Card, Button } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const CancelPage = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{ textAlign: "center", padding: "50px", background: "#f7f7f7" }}
    >
      <Card
        style={{
          maxWidth: "350px",
          margin: "20px auto",
          padding: "20px",
          border: "1px solid #e8e8e8",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CloseCircleOutlined style={{ color: "red", fontSize: "2rem" }} />
        <h1 style={{ fontSize: "2.5rem", marginBottom: "10px" }}>
          Subscription Cancelled
        </h1>
        <p style={{ fontSize: "1.2rem", color: "#777" }}>
          Your subscription process was cancelled. If this was a mistake, you
          can attempt to subscribe again.
        </p>
        <Button
          type="default"
          onClick={() => navigate("/pricing")}
          style={{ margin: "20px 0" }}
        >
          Try Again
        </Button>
        <Button
          type="default"
          onClick={() => navigate("/")}
          style={{ margin: "20px 0" }}
        >
          Back to Homepage
        </Button>
      </Card>
    </div>
  );
};

export default CancelPage;
