import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const ProtectedRoute = ({
  adminOnly = false,
  requiredSubscription = false,
}) => {
  const { user } = useAuth();

  if (!user) {
    // If the user is not logged in, redirect to the login page
    return <Navigate to="/login" />;
  }

  if (adminOnly && !user.isAdmin) {
    // If the route is admin-only and the user is not an admin, redirect to the homepage
    return <Navigate to="/" />;
  }

  if (requiredSubscription && !user.isSubscribed) {
    // If the route requires subscription and the user is not subscribed, redirect to the pricing/upgrade page
    return <Navigate to="/pricing" />;
  }

  // If all checks pass, render the requested component
  return <Outlet />;
};

export default ProtectedRoute;
